import React, { useState, useEffect, useContext } from 'react';
import { Table, Container, Alert, Spinner, Badge, Form, Row, Col, Pagination } from 'react-bootstrap';
import { UserContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../utils/api';

const Feedback = () => {
  const [feedbackList, setFeedbackList] = useState([]);
  const [filteredFeedback, setFilteredFeedback] = useState([]);
  const [loading, setLoading] = useState(true);
  const { handleLogout } = useContext(UserContext);
  const navigate = useNavigate();

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Filters State
  const [searchQuery, setSearchQuery] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [ratingFilter, setRatingFilter] = useState('');
  const [schoolYearFilter, setSchoolYearFilter] = useState('Overall');
  const [userTypeFilter, setUserTypeFilter] = useState('');

  // Fetch feedbacks from API
  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const response = await api.get('/api/feedbacks'); // Fetch all feedback
        setFeedbackList(response.data.feedbacks || []);
      } catch (error) {
        if (error.response?.status === 401) {
          toast.error('Session expired. Please log in again.');
          handleLogout();
        } else {
          console.error('Error fetching feedbacks:', error);
          toast.error('Failed to load feedbacks.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchFeedbacks();
  }, [handleLogout, navigate]);

  // Apply filters
  useEffect(() => {
    let filteredData = feedbackList;

    // Apply search query
    if (searchQuery) {
      filteredData = filteredData.filter(
        (fb) =>
          (fb.email && fb.email.toLowerCase().includes(searchQuery.toLowerCase())) ||
          fb.feedback.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (categoryFilter) {
      filteredData = filteredData.filter((fb) => fb.category === categoryFilter);
    }

    if (ratingFilter) {
      filteredData = filteredData.filter((fb) => fb.rating && fb.rating.toString() === ratingFilter);
    }

    if (schoolYearFilter !== 'Overall') {
      filteredData = filteredData.filter((fb) => {
        const feedbackYear = new Date(fb.created_at).getFullYear(); // Extract year from created_at
        const schoolYearStart = parseInt(schoolYearFilter.split('-')[0]); // Extract start year from filter
    
        return feedbackYear === schoolYearStart || feedbackYear === schoolYearStart + 1;
      });
    }
    
   

    // ✅ Corrected user type classification
    if (userTypeFilter === 'logged_in') {
        filteredData = filteredData.filter((fb) => fb.email); // ✔ All users with emails are logged-in users
    } else if (userTypeFilter === 'guest') {
        filteredData = filteredData.filter((fb) => !fb.email); // ✔ Only session-based users (no email) are guests
    }

    setFilteredFeedback(filteredData);
    setCurrentPage(1);
  }, [searchQuery, categoryFilter, ratingFilter, schoolYearFilter, userTypeFilter, feedbackList]);

  

  // Pagination logic
  const indexOfLastFeedback = currentPage * itemsPerPage;
  const indexOfFirstFeedback = indexOfLastFeedback - itemsPerPage;
  const currentFeedbacks = filteredFeedback.slice(indexOfFirstFeedback, indexOfLastFeedback);

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
        <p>Loading feedback...</p>
      </div>
    );
  }

  return (
    <Container className="mt-5">
      <h3 className="text-center mb-4">User Feedback</h3>

      {/* Filters */}
      <Form className="mb-4">
  <Row className="mb-3 g-2 align-items-end"> {/* Aligns dropdowns properly */}
    {/* First Row (Search, Category, Rating, User Type) */}
    <Col md={3}>
      <Form.Group controlId="searchQuery">
        <Form.Label>Search</Form.Label>
        <Form.Control type="text" placeholder="Email / Feedback" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
      </Form.Group>
    </Col>

    <Col md={2}>
      <Form.Group controlId="categoryFilter">
        <Form.Label>Category</Form.Label>
        <Form.Select value={categoryFilter} onChange={(e) => setCategoryFilter(e.target.value)}>
          <option value="">All</option>
          <option value="chatbot">Chatbot</option>
          <option value="landing_page">Landing Page</option>
        </Form.Select>
      </Form.Group>
    </Col>

    <Col md={2}>
      <Form.Group controlId="ratingFilter">
        <Form.Label>Rating</Form.Label>
        <Form.Select value={ratingFilter} onChange={(e) => setRatingFilter(e.target.value)} disabled={categoryFilter !== 'chatbot'}>
          <option value="">All</option>
          <option value="1">⭐</option>
          <option value="2">⭐⭐</option>
          <option value="3">⭐⭐⭐</option>
          <option value="4">⭐⭐⭐⭐</option>
          <option value="5">⭐⭐⭐⭐⭐</option>
        </Form.Select>
      </Form.Group>
    </Col>

    <Col md={2}>
      <Form.Group controlId="userTypeFilter">
        <Form.Label>User Type</Form.Label>
        <Form.Select value={userTypeFilter} onChange={(e) => setUserTypeFilter(e.target.value)}>
          <option value="">All</option>
          <option value="logged_in">Logged-in Users</option>
          <option value="guest">Guest Users</option>
        </Form.Select>
      </Form.Group>
    </Col>
  </Row>

  {/* Second Row (Start Date & End Date - Right Most Side) */}
  <Row className="mb-3 justify-content-end"> {/* Push to the right */}
  <Col md={2}>
  <Form.Group controlId="schoolYearFilter">
    <Form.Label>School Year</Form.Label>
    <Form.Select
      value={schoolYearFilter}
      onChange={(e) => setSchoolYearFilter(e.target.value)}
    >
      <option value="Overall">Overall</option>
      <option value="2022-2023">2022-2023</option>
      <option value="2023-2024">2023-2024</option>
      <option value="2024-2025">2024-2025</option>
    </Form.Select>
  </Form.Group>
</Col>
  </Row>
</Form>


      {/* No Guest Feedbacks Fallback */}
      {userTypeFilter === 'guest' && filteredFeedback.length === 0 && (
        <Alert variant="warning" className="text-center">
          No feedback from guest users.
        </Alert>
      )}

      {/* Feedback Table */}
      <Table striped bordered hover responsive>
        <thead className="table-dark">
          <tr>
            <th>Email / Session ID</th>
            <th>Feedback</th>
            <th>Category</th>
            <th>Rating</th>
            <th>Submitted At</th>
          </tr>
        </thead>
        <tbody>
          {currentFeedbacks.map((feedback, index) => (
            <tr key={index}>
              <td>{feedback.email ? feedback.email : <Badge bg="secondary">Guest</Badge>}</td>
              <td>{feedback.feedback}</td>
              <td>
                <Badge bg={feedback.category === 'chatbot' ? 'primary' : 'success'}>
                  {feedback.category === 'chatbot' ? 'Chatbot' : 'Landing Page'}
                </Badge>
              </td>
              <td>{feedback.category === 'chatbot' ? feedback.rating || 'N/A' : 'N/A'}</td>
              <td>{new Date(feedback.created_at).toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination */}
      <Pagination className="justify-content-center">
        {[...Array(Math.ceil(filteredFeedback.length / itemsPerPage)).keys()].map((number) => (
          <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
            {number + 1}
          </Pagination.Item>
        ))}
      </Pagination>
    </Container>
  );
};

export default Feedback;

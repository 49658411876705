import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import api from '../../utils/api';
import { toast } from 'react-toastify';
import { FaStar } from 'react-icons/fa';

const FeedbackModal = ({ show, onClose, isLoggedIn, target, category }) => {
  const [rating, setRating] = useState(0);
  const [feedbackComment, setFeedbackComment] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false); // Track if user already submitted feedback

  useEffect(() => {
    if (show) {
      checkExistingFeedback(); // Check if user already submitted feedback when modal opens
    }
  }, [show]);

  const checkExistingFeedback = async () => {
    try {
      const response = await api.get('/api/check-feedback/', {
        headers: isLoggedIn
          ? { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
          : {},
        params: { category }, // Send category to check for duplicates
      });

      if (response.status === 200 && response.data.exists) {
        setHasSubmitted(true);
        toast.info('You have already submitted feedback today.');
      } else {
        setHasSubmitted(false);
      }
    } catch (error) {
      console.error('Error checking feedback:', error);
    }
  };

  const handleSubmitFeedback = async () => {
    if (!rating) {
      toast.error('Please provide a rating.');
      return;
    }
    
    if (hasSubmitted) {
      toast.info('You have already submitted feedback today.');
      return;
    }
  
    setLoading(true);
    
    try {
      const response = await api.post('/api/submit-feedback/', {
        category, // 'chatbot' or 'landing_page'
        rating,
        feedback: feedbackComment.trim() || null, // Optional comment
      }, {
        headers: isLoggedIn
          ? { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
          : {},
      });
  
      if (response.status === 201) {
        toast.success('Thank you for your feedback!');
        setHasSubmitted(true);
        setRating(0);
        setFeedbackComment('');
        setTimeout(onClose, 500); // Ensure UI updates before closing modal
      } else {
        toast.error(response.data?.error || 'Failed to submit feedback.');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast.error(error.response?.data?.error || 'Network error, please try again.');
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Modal title="Rate Your Experience" show={show} onHide={onClose} target={target}>
      <div className="p-3 text-center">
        {hasSubmitted ? (
          <p>You have already submitted feedback today.</p>
        ) : (
          <>
            <p>How would you rate your experience with {category === 'chatbot' ? 'the Chatbot' : 'our Website'}?</p>
            <div className="d-flex justify-content-center mb-3">
              {[1, 2, 3, 4, 5].map((star) => (
                <FaStar
                  key={star}
                  size={30}
                  onClick={() => setRating(star)}
                  style={{ cursor: 'pointer', margin: '5px', color: star <= rating ? '#ffc107' : '#e4e5e9' }}
                />
              ))}
            </div>
            <textarea
              className="form-control mb-3"
              rows="3"
              placeholder="Tell us more about your experience (optional)..."
              value={feedbackComment}
              onChange={(e) => setFeedbackComment(e.target.value)}
            />
            <button
              className="btn btn-primary"
              onClick={handleSubmitFeedback}
              disabled={loading || hasSubmitted}
            >
              {loading ? 'Submitting...' : 'Submit Feedback'}
            </button>
          </>
        )}
      </div>
    </Modal>
  );
};

export default FeedbackModal;

import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  Table,
  Container,
  Form,
  Alert,
  Modal,
  Spinner,
} from "react-bootstrap";
import api from "../../utils/api"; // API utility to handle requests
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const Management = () => {
  const [sections, setSections] = useState([]);
  const [newSectionName, setNewSectionName] = useState("");
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSections, setSelectedSections] = useState([]); // For bulk actions
  const [students, setStudents] = useState([]); // For viewing students by section
  const [maxStarts, setMaxStarts] = useState({});
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [userSchoolId, setUserSchoolId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStudentsModal, setShowStudentsModal] = useState(false);
  const [sectionToDelete, setSectionToDelete] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc"); // Default: ascending
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage] = useState(10); // Number of students per page
  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = students.slice(indexOfFirstStudent, indexOfLastStudent);
  const [showAllStudentsModal, setShowAllStudentsModal] = useState(false);
  const [allStudents, setAllStudents] = useState([]);
  const [allStudentsSearchQuery, setAllStudentsSearchQuery] = useState("");
  const [allStudentsDateFilter, setAllStudentsDateFilter] = useState("");
  const [loadingAllStudents, setLoadingAllStudents] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);
// Sorting state for "All Students" table
  const [sortFieldAll, setSortFieldAll] = useState(null);
  const [sortOrderAll, setSortOrderAll] = useState("asc");
  const [previewCurrentPage, setPreviewCurrentPage] = useState(1);
  const [previewStudentsPerPage] = useState(10); // Adjust the number of students per page
  const indexOfLastPreviewStudent = previewCurrentPage * previewStudentsPerPage;
  const indexOfFirstPreviewStudent = indexOfLastPreviewStudent - previewStudentsPerPage;

  // Sorting state for "Section Students" table
  const [sortFieldSection, setSortFieldSection] = useState(null);
  const [sortOrderSection, setSortOrderSection] = useState("asc");
  const [resendLoading, setResendLoading] = useState({});

  
  // Add an "All Students" pagination if needed
  const [allStudentsCurrentPage, setAllStudentsCurrentPage] = useState(1);
  const [allStudentsPerPage] = useState(10);
  // For single-student add form
 // Existing states...
  const [showSingleStudentModal, setShowSingleStudentModal] = useState(false);
  const [singleStudent, setSingleStudent] = useState({
    id_no: "",
    full_name: "",
    email: "",
    gender: "",
    grade_level: "",
    section: "",
    strand: "",
  });

  // ADD THIS:
  const [validationErrors, setValidationErrors] = useState({
    id_no: "",
    full_name: "",
    email: "",
  });


  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSectionName, setSelectedSectionName] = useState(""); // State for section name
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false);
  const [csvFile, setCsvFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [csvData, setCsvData] = useState([]); // Holds previewed CSV data
  const paginatedPreviewStudents = (csvData || []).slice(indexOfFirstPreviewStudent, indexOfLastPreviewStudent);

  const [newStudent, setNewStudent] = useState({
  id_no: "",
  full_name: "",
  email: "",
  gender: "",
  grade_level: "",
  section: "",
  strand: "",
});
  const [showCSVModal, setShowCSVModal] = useState(false); // To preview CSV contents

  const filteredStudents = students.filter((student) =>
    student.full_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    student.id_no.includes(searchQuery)
  );
  const handleOpenAllStudents = async () => {
    setShowAllStudentsModal(true);
    // Fetch all students from backend
    fetchAllStudents();
  };
  const openSingleStudentModal = () => {
    setSingleStudent({
      id_no: "",
      full_name: "",
      email: "",
      gender: "",
      grade_level: "",
      section: "",
      strand: "",
    });
    setShowSingleStudentModal(true);
  };
  
  const handleSingleStudentChange = (e) => {
    setSingleStudent((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const handlePreviewPageChange = (newPage) => {
    setPreviewCurrentPage(newPage);
  };
  
  const handleAddSingleStudent = async () => {
    // 1. Reset validation errors
    setValidationErrors({
      id_no: "",
      full_name: "",
      email: "",
    });
  
    // 2. Basic required-fields check
    if (!singleStudent.id_no || !singleStudent.full_name || !singleStudent.email) {
      toast.error("ID, full name, and email are required.", { position: "top-right" });
      return;
    }
  
    try {
      // 3. Check for duplicates
      const checkResponse = await api.post("/users/check-duplicate/", {
        id_no: singleStudent.id_no,
        full_name: singleStudent.full_name,
        email: singleStudent.email,
      });
  
      // If the backend returns { errors: {...} }
      if (checkResponse.data?.errors) {
        setValidationErrors(checkResponse.data.errors);
        Object.values(checkResponse.data.errors).forEach((msg) => {
          toast.error(msg, { position: "top-right" });
        });
        return; // Stop here; don't proceed to register
      }
  
      // 4. Construct payload without mobile_no and birthday
      const payload = {
        id_no: singleStudent.id_no,
        full_name: singleStudent.full_name,
        email: singleStudent.email,
        sex: singleStudent.gender, // or singleStudent.sex
        strand: singleStudent.strand,
        grade_level: singleStudent.grade_level,
        school_id: userSchoolId, // ensure you have this from state
        section_id: singleStudent.section, // if your modal stores the SECTION ID
        walkIn: "no", // so the backend knows it's a non-walk-in
      };
  
      const addResponse = await api.post("/register/", payload);
  
      // Success - refresh student list and close modal
      fetchAllStudents();
      toast.success("Student added successfully!", { position: "top-right" });
      setShowSingleStudentModal(false);
  
    } catch (err) {
      // 5. Handle backend validation errors
      if (err.response?.data?.errors) {
        setValidationErrors(err.response.data.errors);
        Object.values(err.response.data.errors).forEach((msg) => {
          toast.error(msg, { position: "top-right" });
        });
      } else if (err.response?.data?.message) {
        toast.error(err.response.data.message, { position: "top-right" });
      } else {
        toast.error("Error adding student. Please try again.", { position: "top-right" });
      }
    }
  };
  

  
  
    // NEW: Function to fetch all students
    const fetchAllStudents = async () => {
      setLoadingAllStudents(true);
      try {
        const response = await api.get("/users/all-students/"); 
        // ^ you'll create an endpoint like /users/all-students
        setAllStudents(response.data); 
      } catch (err) {
        toast.error("Error fetching all students.", { position: "top-right" });
      } finally {
        setLoadingAllStudents(false);
      }
    };
     // NEW: Filter logic for All Students
  const filteredAllStudents = allStudents.filter(student => {
    // 1) Basic search by name or ID
    const matchSearch = 
      student.full_name.toLowerCase().includes(allStudentsSearchQuery.toLowerCase()) ||
      student.id_no.includes(allStudentsSearchQuery);

    // 2) Optional date filter 
    let matchDate = true;
if (allStudentsDateFilter) {
  const studentDate = new Date(student.date_added).toLocaleDateString('en-CA'); // "YYYY-MM-DD"
  matchDate = (studentDate === allStudentsDateFilter);
}


    return matchSearch && matchDate;
  });

  // Pagination for all students
  const indexOfLastAllStudent = allStudentsCurrentPage * allStudentsPerPage;
  const indexOfFirstAllStudent = indexOfLastAllStudent - allStudentsPerPage;
  const paginatedAllStudents = filteredAllStudents.slice(indexOfFirstAllStudent, indexOfLastAllStudent);
  const [sectionNames, setSectionNames] = useState([""]); // Array of section names
  const addNewSectionField = () => {
    setSectionNames([...sectionNames, ""]);
  };
  // Handle changes in input fields
const handleSectionNameChange = (e, index) => {
  const updatedSections = [...sectionNames];
  updatedSections[index] = e.target.value;
  setSectionNames(updatedSections);
};
// Remove a section input field
const removeSection = (index) => {
  setSectionNames(sectionNames.filter((_, i) => i !== index));
};
  // Handler for pagination
  const handleAllStudentsPageChange = (newPage) => {
    setAllStudentsCurrentPage(newPage);
  };

  // (B) Resend Email function
  const handleResendEmail = async (userId) => {
    setResendLoading((prev) => ({ ...prev, [userId]: true })); // Set loading for specific user
    
    try {
      await api.post(`/users/${userId}/resend-activation-email/`);
      toast.success("Activation email resent successfully!", { position: "top-right" });
    } catch (err) {
      toast.error(
        err.response?.data?.message || "An error occurred. Please try again.",
        { position: "top-right" }
      );
    } finally {
      setResendLoading((prev) => ({ ...prev, [userId]: false })); // Reset loading state
    }
  };
  
  

 
  // Paginate the filtered results
  const paginatedStudents = filteredStudents.slice(
    (currentPage - 1) * studentsPerPage,
    currentPage * studentsPerPage
  );
  const handleCloseCSVModal = () => {
    setPreviewCurrentPage(1); // Reset pagination
    setShowCSVModal(false);
 };
 
  
  const handleCloseStudentsModal = () => {
    setCurrentPage(1); // Reset to the first page
    setShowStudentsModal(false); // Close the modal
  };
  
   // Add this function
   const handleSort = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  
    if (!sections || sections.length === 0) {
      console.error("No data to sort");
      return;
    }
  
    const sortedSections = [...sections].sort((a, b) => {
      if (typeof a[field] === "string" && typeof b[field] === "string") {
        return order === "asc"
          ? a[field].localeCompare(b[field])
          : b[field].localeCompare(a[field]);
      }
      if (a[field] < b[field]) return order === "asc" ? -1 : 1;
      if (a[field] > b[field]) return order === "asc" ? 1 : -1;
      return 0;
    });
  
    setSections(sortedSections);
  };
  
  
  useEffect(() => {
    const fetchSchoolAndSections = async () => {
      setLoading(true); // Show loading spinner
      try {
        const userResponse = await api.get("/auth/user");
        const schoolId = userResponse.data.school_id;
        setUserSchoolId(schoolId);
  
        const response = await api.get(`/sections?school_id=${schoolId}`);
        setSections(response.data);
      } catch (err) {
        toast.error("Error fetching sections.", { position: "top-right" });
      } finally {
        setLoading(false); // Hide loading spinner
      }
    };
  
    fetchSchoolAndSections();
  }, []);
  const handleAddManualStudent = () => {
    if (!newStudent.id_no || !newStudent.full_name || !newStudent.email) {
      toast.error("ID number, full name, and email are required.", { position: "top-right" });
      return;
    }
  
    setCsvData(prevData => [...prevData, newStudent]);
    setNewStudent({
      id_no: "",
      full_name: "",
      email: "",
      gender: "",
      grade_level: "",
      section: "",
      strand: "",
    });
    toast.success("Student added successfully!", { position: "top-right" });
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCsvFile(file); // ✅ Update file state
    } else {
      setCsvFile(null); // ✅ Reset if no file is chosen
    }
  
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const text = event.target.result;
        const rows = text.split("\n").map(row => row.split(","));
        if (rows.length > 1) {
          const headers = rows[0].map(header => header.trim().toLowerCase());
          const data = rows.slice(1).map(row => {
            let obj = {};
            headers.forEach((header, index) => {
              obj[header] = row[index] ? row[index].trim() : "";
            });
            return obj;
          });
          setCsvData(data.filter(student => student.id_no)); // ✅ Filter empty rows
          setShowCSVModal(true);
        }
      };
      reader.readAsText(file);
    }
  };
  

  
  
  
  const handleUploadCSV = async () => {
    if (!csvFile) {
        console.error("No file selected.");
        toast.error("Please select a CSV file to upload.", { position: "top-right" });
        return;
    }

    const reader = new FileReader();
    reader.onload = async (event) => {
        const text = event.target.result;
        const rows = text.split("\n").map(row => row.split(","));

        if (rows.length < 2) { // At least one row of data + headers
            toast.error("CSV file is empty or has no data.", { position: "top-right" });
            return;
        }

        // Extract headers from the first row
        const headers = rows[0].map(header => header.trim().toLowerCase());
        const expectedHeaders = ["id_no", "full_name", "email", "gender", "grade_level", "section", "strand"];

        // Check if all required columns exist
        const missingColumns = expectedHeaders.filter(col => !headers.includes(col));
        if (missingColumns.length > 0) {
            toast.error(`Missing required columns: ${missingColumns.join(", ")}`, { position: "top-right" });
            return;
        }

        const formData = new FormData();
        formData.append("file", csvFile); // Ensure correct key matches the backend

        console.log("Uploading file:", csvFile.name);
        console.log("FormData contents:", [...formData.entries()]); // Debug FormData

        // Retrieve the token
        const token = localStorage.getItem("authToken");

        if (!token) {
            console.error("Error: No authentication token found.");
            toast.error("Authentication error. Please log in again.", { position: "top-right" });
            return;
        }

        setUploading(true);
        try {
            const response = await api.post("/students/upload_nonwalkin/", formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            console.log("Response from API:", response.data);

            if (response.data.created_users.length > 0) {
                toast.success(`${response.data.created_users.length} students added successfully.`, { position: "top-right" });
            }

            if (response.data.errors.length > 0) {
                response.data.errors.forEach(error => toast.warning(error, { position: "top-right" }));
            }
        } catch (err) {
            console.error("Error uploading CSV:", err.response?.data || err.message);
            toast.error("Error uploading CSV. Please check the file format.", { position: "top-right" });
        } finally {
            setUploading(false);
            setCsvFile(null);
        }
    };

    reader.readAsText(csvFile);
};

const handleDownloadTemplate = () => {
  // Fetch sections dynamically
  const availableSections = sections.map((section) => section.name);

  // Predefined strands
  const availableStrands = [
    "ABM",
    "ARTS & DESIGN",
    "STEM",
    "HUMSS",
    "TVL - Information and Communications Technology",
    "TVL - Home Economics",
    "TVL - Agri-Fishery Arts",
    "TVL - Industrial Arts",
  ];

  // Define available options
  const gradeLevels = ["11", "12"]; // Grade Level options
  const genderOptions = ["Male", "Female"]; // Gender options

  // Create a worksheet for dropdown values (Hidden Sheet)
  const dropdownSheet = XLSX.utils.aoa_to_sheet([
    ["Gender", ...genderOptions],
    ["Grade Level", ...gradeLevels],
    ["Sections", ...availableSections],
    ["Strands", ...availableStrands],
  ]);

  // Create the main sheet
  const mainSheet = XLSX.utils.aoa_to_sheet([
    ["id_no", "full_name", "email", "gender", "grade_level", "section", "strand"],
    ["", "", "", "", "", "", ""], // Empty row for input
  ]);

  // Define Data Validations using references from the hidden sheet
  mainSheet["!dataValidation"] = [
    {
      type: "list",
      allowBlank: true,
      sqref: "D2:D100", // Gender dropdown
      formula1: "Dropdown!$B$1:$C$1", // Reference to the "Gender" row in hidden sheet
    },
    {
      type: "list",
      allowBlank: true,
      sqref: "E2:E100", // Grade Level dropdown
      formula1: "Dropdown!$B$2:$C$2",
    },
    {
      type: "list",
      allowBlank: true,
      sqref: "F2:F100", // Section dropdown
      formula1: `Dropdown!$B$3:$${String.fromCharCode(65 + availableSections.length)}$3`,
    },
    {
      type: "list",
      allowBlank: true,
      sqref: "G2:G100", // Strand dropdown
      formula1: `Dropdown!$B$4:$${String.fromCharCode(65 + availableStrands.length)}$4`,
    },
  ];

  // Create a new workbook
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, mainSheet, "Student Template");
  XLSX.utils.book_append_sheet(wb, dropdownSheet, "Dropdown"); // Hidden sheet

  // Hide the dropdown sheet
  wb.Sheets["Dropdown"]["!hidden"] = true;

  // Generate and save the Excel file
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const file = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(file, "student_template.xlsx");
};
// Add this function here
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to the first page
  };

  const handleAddSection = async () => {
    // Remove empty or duplicate section names
    const trimmedSections = sectionNames
      .map(name => name.trim()) // Trim spaces
      .filter((name, index, self) => name && self.indexOf(name) === index); // Remove empty & duplicate names
  
    if (trimmedSections.length === 0) {
      toast.error("At least one valid section name is required.", { position: "top-right" });
      return;
    }
  
    // Check for existing sections
    const existingSections = sections.map(section => section.name.toLowerCase());
    const duplicateSections = trimmedSections.filter(name => existingSections.includes(name.toLowerCase()));
  
    if (duplicateSections.length > 0) {
      toast.error(`Sections already exist: ${duplicateSections.join(", ")}`, { position: "top-right" });
      return;
    }
  
    setLoading(true);
    try {
      const response = await api.post("/sections/add/", {
        sections: trimmedSections, // Send array of section names
        school_id: userSchoolId,
      });
  
      // Update the state with new sections
      setSections(prevSections => [...prevSections, ...response.data]);
      setSectionNames([""]); // Reset input fields
      toast.success(`${response.data.length} section(s) added successfully!`, { position: "top-right" });
      setShowAddModal(false);
    } catch (err) {
      toast.error(err.response?.data?.error || "Error adding new section(s).", { position: "top-right" });
    } finally {
      setLoading(false);
    }
  };
  
  const handleExportCSV = () => {
    // Use the array you want to export, e.g. filteredAllStudents
    const exportData = filteredAllStudents; 
    if (!exportData || exportData.length === 0) {
      toast.warning("No students to export.", { position: "top-right" });
      return;
    }
  
    let csvContent = "data:text/csv;charset=utf-8,";
    // CSV headers -> only the 5 columns we want
    csvContent += ["id_no", "full_name", "email", "strand", "section"].join(",") + "\n";
  
    exportData.forEach((stud) => {
      const row = [
        `'${stud.id_no}'`,    // Single quote to avoid scientific notation
        `"${stud.full_name}"`,
        `"${stud.email}"`,
        `"${stud.strand || ""}"`,
        `"${stud.section_name}"`,
      ].join(",");
      csvContent += row + "\n";
    });
  
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "all_students.csv");
    document.body.appendChild(link);
    link.click();
    link.remove();
  };
  
  

  const handleEditSection = (section) => {
    setSelectedSection(section);
    setShowModal(true);
  };

  const handleSaveEdit = async () => {
    if (!selectedSection || !selectedSection.name) {
      toast.error("Section name cannot be empty.", { position: "top-right" });
      return;
    }
    setLoading(true);
  
    try {
      const response = await api.put(`/sections/${selectedSection.id}/`, {
        name: selectedSection.name,
        school_id: userSchoolId,
      });
  
      setSections((prevSections) =>
        prevSections.map((section) =>
          section.id === response.data.id ? response.data : section
        )
      );
  
      toast.success("Section updated successfully.", { position: "top-right" });
      setShowModal(false);
    } catch (err) {
      toast.error("Error updating section.", { position: "top-right" });
    } finally {
      setLoading(false);
    }
  };
  
  

  const handleDeleteConfirmation = (section) => {
    setSectionToDelete(section);
    setShowDeleteModal(true);
  };

  const handleDeleteSection = async () => {
    setDeleting(true);
  
    try {
      await api.delete(`/sections/${sectionToDelete.id}/delete/`);
      setSections((prevSections) =>
        prevSections.filter((section) => section.id !== sectionToDelete.id)
      );
  
      toast.success("Section deleted successfully.", { position: "top-right" });
      setShowDeleteModal(false);
    } catch (err) {
      toast.error("Error deleting section.", { position: "top-right" });
    } finally {
      setDeleting(false);
    }
  };
  

  const handleSelectSection = (sectionId) => {
    setSelectedSections((prevSelected) =>
      prevSelected.includes(sectionId)
        ? prevSelected.filter((id) => id !== sectionId)
        : [...prevSelected, sectionId]
    );
  };

  const handleSelectAll = () => {
    if (selectedSections.length === sections.length) {
      setSelectedSections([]);
    } else {
      setSelectedSections(sections.map((section) => section.id));
    }
  };
  const handleToggleActive = async (userId, currentStatus) => {
    try {
      await api.patch(`/users/${userId}/activate/`, {
        is_active: !currentStatus, // Toggle status
      });
  
      // ✅ Update real-time in both `allStudents` and `students`
      setAllStudents((prevStudents) =>
        prevStudents.map((student) =>
          student.id === userId ? { ...student, is_active: !currentStatus } : student
        )
      );
  
      setStudents((prevStudents) =>
        prevStudents.map((student) =>
          student.id === userId ? { ...student, is_active: !currentStatus } : student
        )
      );
  
      toast.success(
        `User has been ${!currentStatus ? "activated" : "deactivated"} successfully.`,
        { position: "top-right" }
      );
    } catch (err) {
      toast.error("Error updating active status.", { position: "top-right" });
    }
  };
  
  
  const handleBulkDelete = async () => {
    if (selectedSections.length === 0) {
      toast.error("No sections selected for deletion.", { position: "top-right" });
      return;
    }
  
    setDeleting(true);
  
    try {
      const response = await api.post("/sections/bulk-delete/", {
        ids: selectedSections,
      });
  
      setSections((prevSections) =>
        prevSections.filter((section) => !selectedSections.includes(section.id))
      );
      setSelectedSections([]); // Clear selection
      toast.success("Selected sections deleted successfully.", { position: "top-right" });
    } catch (err) {
      toast.error("Error deleting selected sections.", { position: "top-right" });
    } finally {
      setDeleting(false);
    }
  };
  
  
  
  const handleUpdateMaxStarts = async (userId) => {
    const newMaxStarts = maxStarts[userId];
  
    if (!newMaxStarts || parseInt(newMaxStarts) <= 0) {
      toast.error("Invalid max starts value.", { position: "top-right" });
      return;
    }
  
    try {
      await api.patch(`/users/${userId}/update-max-chatbot-starts/`, {
        max_chatbot_starts: newMaxStarts,
      });
  
      setStudents((prevStudents) =>
        prevStudents.map((student) =>
          student.id === userId
            ? { ...student, max_chatbot_starts: newMaxStarts }
            : student
        )
      );
  
      toast.success("Max chatbot starts updated successfully.", { position: "top-right" });
    } catch (err) {
      toast.error("Error updating max chatbot starts.", { position: "top-right" });
    }
  };
  
  // For the "All Students" modal
const handleSortAllStudents = (field) => {
  const order = sortFieldAll === field && sortOrderAll === "asc" ? "desc" : "asc";
  setSortFieldAll(field);
  setSortOrderAll(order);

  // Use filteredAllStudents, which is your currently displayed "All Students".
  const sorted = [...filteredAllStudents].sort((a, b) => {
    if (typeof a[field] === "string" && typeof b[field] === "string") {
      return order === "asc"
        ? a[field].localeCompare(b[field])
        : b[field].localeCompare(a[field]);
    }
    return order === "asc" ? a[field] - b[field] : b[field] - a[field];
  });

  // Update the main allStudents state so the sorted data appears
  setAllStudents(sorted);
  // Reset pagination to page 1
  setAllStudentsCurrentPage(1);
};

// For the "View Students in a Section" modal
const handleSortSectionStudents = (field) => {
  const order = sortFieldSection === field && sortOrderSection === "asc" ? "desc" : "asc";
  setSortFieldSection(field);
  setSortOrderSection(order);

  // Use filteredStudents or students, depending on how you display them
  const sorted = [...filteredStudents].sort((a, b) => {
    if (typeof a[field] === "string" && typeof b[field] === "string") {
      return order === "asc"
        ? a[field].localeCompare(b[field])
        : b[field].localeCompare(a[field]);
    }
    return order === "asc" ? a[field] - b[field] : b[field] - a[field];
  });

  // Update the main students state so the sorted data appears
  setStudents(sorted);
  // Reset pagination to page 1
  setCurrentPage(1);
};

  
  
  const handleSortSections = (key) => {
    const sortedSections = [...sections].sort((a, b) =>
      a[key] > b[key] ? 1 : -1
    );
    setSections(sortedSections);
  };


  const handleViewStudents = async (sectionId) => {
    setLoading(true);
    try {
      const section = sections.find((sec) => sec.id === sectionId);
      setSelectedSectionName(section?.name || ""); // Set the section name for the modal title
  
      const response = await api.get(`/sections/${sectionId}/students/`);
      const sortedStudents = response.data
        .map((student) => ({
          ...student,
          has_taken_test: student.has_taken_test, // True if test is taken
          test_date: student.test_date
            ? new Date(student.test_date).toLocaleDateString("en-GB", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            : "Not Started",
        }))
        .sort((a, b) => {
          if (a.has_taken_test && !b.has_taken_test) return -1; // "Taken" first
          if (!a.has_taken_test && b.has_taken_test) return 1;
          return 0; // Maintain order within groups
        });
      setStudents(sortedStudents);
      setShowStudentsModal(true);
    } catch (err) {
      toast.error("Error fetching students for this section.", {
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };
  
  

  if (loading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
        <p>Loading sections...</p>
      </div>
    );
  }
  
  if (!sections || sections.length === 0) {
    return (
      <div className="text-center mt-5">
        <Alert variant="info">No sections available for your school.</Alert>
      </div>
    );
  }
// Handle Bulk Delete Confirmation
const confirmBulkDelete = () => {
  if (selectedSections.length === 0) {
    toast.error("No sections selected for deletion.", { position: "top-right" });
    return;
  }
  setShowBulkDeleteModal(true);
};

// Handle Confirmed Bulk Delete
const handleConfirmedBulkDelete = async () => {
  setDeleting(true);
  try {
    await api.post("/sections/bulk-delete/", { ids: selectedSections });

    setSections((prevSections) =>
      prevSections.filter((section) => !selectedSections.includes(section.id))
    );
    setSelectedSections([]); // Clear selection
    toast.success("Selected sections deleted successfully.", { position: "top-right" });
    setShowBulkDeleteModal(false);
  } catch (err) {
    toast.error("Error deleting selected sections.", { position: "top-right" });
  } finally {
    setDeleting(false);
  }
};

  return (
    
    <Container className="my-4">
      <h2 className="text-center mb-4 fw-bold">Management Dashboard</h2>

      {/* Bulk Actions */}
      <Row className="mb-3 d-flex align-items-center justify-content-between">
  <Col className="d-flex gap-2">
    <Button variant="success" onClick={() => setShowAddModal(true)}>
      + Add Section
    </Button>

    {selectedSections.length > 0 && (
      <Button
        variant="danger"
        onClick={confirmBulkDelete}
        disabled={deleting}
      >
        {deleting ? <Spinner animation="border" size="sm" /> : "Delete Selected"}
      </Button>
    )}
  </Col>

  {/* Right-align the View All Students button */}
  <Col className="d-flex justify-content-end">
    <Button
      onClick={handleOpenAllStudents}
      style={{
        background: "linear-gradient(135deg, #007bff, #0056b3)", 
        color: "#fff",
        fontWeight: "bold",
        fontSize: "1rem",
        padding: "10px 15px",
        borderRadius: "6px",
        boxShadow: "0px 3px 8px rgba(0, 123, 255, 0.3)",
        transition: "0.3s",
        border: "none",
      }}
      onMouseEnter={(e) => (e.target.style.opacity = "0.85")}
      onMouseLeave={(e) => (e.target.style.opacity = "1")}
    >
      <i className="bi bi-people-fill me-2"></i> View All Students
    </Button>
  </Col>
</Row>


<Row className="mb-3">
  <Col md={6}>
  <Form.Group controlId="csvUpload">
  <Form.Label><strong>Batch Student Registration (CSV)</strong></Form.Label>
  <div className="d-flex align-items-center">
    <div className="custom-file w-50">
      <Form.Control
        type="file"
        accept=".csv"
        id="fileInput"
        onChange={handleFileChange}
        className="custom-file-input"
        style={{ display: "none" }} // Hide default file input
      />
      <label
        htmlFor="fileInput"
        className="btn btn-outline-primary w-100 d-flex justify-content-between align-items-center"
        style={{
          padding: "10px",
          borderRadius: "6px",
          fontWeight: "bold",
          border: "2px solid #007bff",
          cursor: "pointer",
        }}
      >
        <span>{csvFile ? csvFile.name : "Choose File"}</span>
        <i className="bi bi-upload"></i>
      </label>
    </div>
{/* Download Template Button */}
<Button
  variant="success"
  onClick={handleDownloadTemplate}
  className="ms-2 d-flex align-items-center justify-content-center position-relative"
  style={{
    background: "#217346",
    padding: "10px",
    borderRadius: "6px",
    border: "none",
    boxShadow: "0px 3px 6px rgba(33, 115, 70, 0.3)",
    transition: "width 0.3s ease, opacity 0.2s ease",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    overflow: "hidden",
    whiteSpace: "nowrap",
  }}
  onMouseEnter={(e) => {
    e.currentTarget.style.width = "160px"; // Expand width
    e.currentTarget.querySelector(".icon").style.opacity = "0"; // Hide icon
    e.currentTarget.querySelector(".text").style.opacity = "1"; // Show text
  }}
  onMouseLeave={(e) => {
    e.currentTarget.style.width = "40px"; // Shrink back
    e.currentTarget.querySelector(".icon").style.opacity = "1"; // Show icon
    e.currentTarget.querySelector(".text").style.opacity = "0"; // Hide text
  }}
>
  {/* Icon - Visible by default */}
  <i className="bi bi-file-earmark-excel icon" 
     style={{ fontSize: "1.5rem", color: "white", transition: "opacity 0.2s ease" }}>
  </i>

  {/* Text - Hidden by default, appears on hover */}
  <span 
    className="text" 
    style={{
      color: "white",
      fontSize: "0.9rem",
      fontWeight: "bold",
      position: "absolute",
      left: "15px",
      opacity: 0, // Initially hidden
      transition: "opacity 0.2s ease",
    }}
  >
    Download Template
  </span>
</Button>

  </div>
</Form.Group>

  </Col>
</Row>



      {/* Manage Sections */}
      <Row>
  <Col md={12}>
  <Card 
  className="mb-4" 
  style={{
    margin: "0 auto", // Center the card
    width: "fit-content", // Adjust card width to fit table content
    padding: "0", // Remove extra padding
    border: "none", // Remove any default border
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for better visibility
  }}
>
  <Card.Header
    className="text-center"
    style={{
      backgroundColor: "#f9c846", // Soft gold background for better contrast
      fontWeight: "bold",
      fontSize: "22px", // Slightly larger font size
      padding: "20px", // Increased padding for a more balanced look
      borderBottom: "2px solid #e5a700", // Add a border for visual separation
    }}
  >
    <span style={{ textShadow: "1px 1px 2px rgba(0,0,0,0.1)" }}>
      Manage Sections
    </span>
  </Card.Header>

  <Card.Body style={{ padding: "0" }}>
    <div
      style={{
        overflowX: "auto", // Allow horizontal scrolling for smaller screens
        backgroundColor: "#ffffff", // Ensure a clean white background
        borderRadius: "5px", // Add slight rounding to corners
      }}
    >
      <Table
        striped
        bordered
        hover
        responsive
        style={{
          margin: "0", // Align the table with the card
          borderCollapse: "collapse", // Ensure proper table structure
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)", // Subtle shadow for depth
        }}
      >
        <thead
          style={{
            backgroundColor: "#f5f5f5", // Light gray for header background
            color: "#333", // Darker text for readability
            fontWeight: "bold", // Bold font for header text
          }}
        >
          <tr>
            <th className="text-center">
              <Form.Check
                type="checkbox"
                checked={selectedSections.length === sections.length}
                onChange={handleSelectAll}
              />
            </th>
            <th className="text-center">#</th>
            <th
              className="text-start"
              onClick={() => handleSort("name")}
              style={{ cursor: "pointer" }}
            >
              Section Name{" "}
              <i
                className={`bi ${
                  sortField === "name"
                    ? sortOrder === "asc"
                      ? "bi-arrow-up"
                      : "bi-arrow-down"
                    : "bi-arrow-down-up"
                }`}
                style={{
                  color: sortField === "name" ? "#007bff" : "inherit", // Highlight active column
                }}
              ></i>
            </th>
            <th
              className="text-center"
              onClick={() => handleSort("student_count")}
              style={{ cursor: "pointer" }}
            >
              Student Count{" "}
              <i
                className={`bi ${
                  sortField === "student_count"
                    ? sortOrder === "asc"
                      ? "bi-arrow-up"
                      : "bi-arrow-down"
                    : "bi-arrow-down-up"
                }`}
                style={{
                  color: sortField === "student_count" ? "#007bff" : "inherit",
                }}
              ></i>
            </th>
            <th
              className="text-center"
              onClick={() => handleSort("created_at")}
              style={{ cursor: "pointer" }}
            >
              Date Created{" "}
              <i
                className={`bi ${
                  sortField === "created_at"
                    ? sortOrder === "asc"
                      ? "bi-arrow-up"
                      : "bi-arrow-down"
                    : "bi-arrow-down-up"
                }`}
                style={{
                  color: sortField === "created_at" ? "#007bff" : "inherit",
                }}
              ></i>
            </th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {sections.length > 0 ? (
            sections.map((section, index) => (
              <tr key={section.id}>
                <td>
                  <Form.Check
                    type="checkbox"
                    checked={selectedSections.includes(section.id)}
                    onChange={() => handleSelectSection(section.id)}
                  />
                </td>
                <td>{index + 1}</td>
                <td>{section.name}</td>
                <td>{section.student_count}</td>
                <td>
                  {section.created_at
                    ? new Date(section.created_at).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    : "N/A"}
                </td>
                <td className="text-center">
                  <div className="d-flex justify-content-center">
                    <Button
                      variant="info"
                      size="sm"
                      className="me-2"
                      onClick={() => handleViewStudents(section.id)}
                    >
                      View Students
                    </Button>
                    <Button
                      variant="warning"
                      size="sm"
                      className="me-2"
                      onClick={() => handleEditSection(section)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDeleteConfirmation(section)}
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6" className="text-center">
                No sections available for your school.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  </Card.Body>
</Card>
<Modal
  show={showAllStudentsModal}
  onHide={() => setShowAllStudentsModal(false)}
  size="xl"
  centered
>
  <Modal.Header closeButton>
    <Modal.Title>All Students</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {loadingAllStudents ? (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" />
      </div>
    ) : (
      <>
        {/* Search & Filters */}
        <Row className="mb-3">
  <Col md={6}>
    <Form.Control
      type="text"
      placeholder="Search by name or ID"
      value={allStudentsSearchQuery}
      onChange={(e) => setAllStudentsSearchQuery(e.target.value)}
    />
  </Col>
  <Col md={4}>
    <Form.Control
      type="date"
      value={allStudentsDateFilter}
      onChange={(e) => setAllStudentsDateFilter(e.target.value)}
    />
  </Col>
</Row>

{/* NEW Buttons Row */}
<Row className="mb-3">
  <Col>
    <Button variant="success" onClick={openSingleStudentModal}>
      Add Student
    </Button>{" "}
    <Button variant="info" onClick={handleExportCSV}>
      Export to CSV
    </Button>
  </Col>
</Row>


        {/* Students Table with Sorting */}
        {filteredAllStudents.length > 0 ? (
          <>
            <Table striped bordered hover responsive>
            <thead>
  <tr>
    <th onClick={() => handleSortAllStudents("id_no")} style={{ cursor: "pointer" }}>
      User ID <i className="bi bi-arrow-down-up ms-2"></i>
    </th>
    <th onClick={() => handleSortAllStudents("full_name")} style={{ cursor: "pointer" }}>
      Full Name <i className="bi bi-arrow-down-up ms-2"></i>
    </th>
    <th onClick={() => handleSortAllStudents("email")} style={{ cursor: "pointer" }}>
      Email <i className="bi bi-arrow-down-up ms-2"></i>
    </th>
    <th onClick={() => handleSortAllStudents("section_name")} style={{ cursor: "pointer" }}>
      Section <i className="bi bi-arrow-down-up ms-2"></i>
    </th>
    <th onClick={() => handleSortAllStudents("date_added")} style={{ cursor: "pointer" }}>
      Date Added <i className="bi bi-arrow-down-up ms-2"></i>
    </th>
    <th onClick={() => handleSortAllStudents("is_active")} style={{ cursor: "pointer" }}>
      Status <i className="bi bi-arrow-down-up ms-2"></i>
    </th>
    <th>Actions</th>
  </tr>
</thead>


<tbody>
  {paginatedAllStudents.map((student, idx) => (
    <tr key={student.id}>
      <td>{student.id_no}</td> {/* User ID */}
      <td>{student.full_name}</td> {/* Full Name */}
      <td>{student.email}</td> {/* Email */}
      <td>{student.section_name || "N/A"}</td> {/* Section */}
      <td>{new Date(student.date_added).toLocaleDateString()}</td> {/* Date Added */}
      <td>
        <span className={student.is_active ? "text-success fw-bold" : "text-danger fw-bold"}>
          {student.is_active ? "Active" : "Inactive"}
        </span>
      </td> {/* Status */}
      <td>
        <div className="d-flex justify-content-center gap-2">
        <Button 
  variant="info" 
  size="sm" 
  onClick={() => handleResendEmail(student.id)}
  disabled={resendLoading[student.id]} // Disable button while loading
>
  {resendLoading[student.id] ? (
    <>
      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
      {" Sending..."}
    </>
  ) : (
    "Resend Email"
  )}
</Button>




          <Button
            variant={student.is_active ? "danger" : "success"}
            size="sm"
            onClick={() => handleToggleActive(student.id, student.is_active)}
          >
            {student.is_active ? "Deactivate" : "Activate"}
          </Button>
        </div>
      </td> {/* Actions */}
    </tr>
  ))}
</tbody>

            </Table>

            {/* Pagination Controls */}
            <div className="d-flex justify-content-between align-items-center mt-3">
              <Button
                variant="secondary"
                disabled={allStudentsCurrentPage === 1}
                onClick={() => handleAllStudentsPageChange(allStudentsCurrentPage - 1)}
              >
                Previous
              </Button>
              <span>
                Page {allStudentsCurrentPage} of {Math.ceil(filteredAllStudents.length / allStudentsPerPage)}
              </span>
              <Button
                variant="secondary"
                disabled={allStudentsCurrentPage >= Math.ceil(filteredAllStudents.length / allStudentsPerPage)}
                onClick={() => handleAllStudentsPageChange(allStudentsCurrentPage + 1)}
              >
                Next
              </Button>
            </div>
          </>
        ) : (
          <p className="text-center">No students found matching your search criteria.</p>
        )}
      </>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowAllStudentsModal(false)}>
      Close
    </Button>
  </Modal.Footer>
</Modal>
{/* Add Single Student Modal */}
<Modal
  show={showSingleStudentModal}
  onHide={() => setShowSingleStudentModal(false)}
  centered
>
  <Modal.Header closeButton>
    <Modal.Title>Add Single Student</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  <Form>
  {/* ID Number */}
  <Form.Group className="mb-2">
    <Form.Label>ID Number</Form.Label>
    <Form.Control
      name="id_no"
      type="text"
      value={singleStudent.id_no}
      onChange={handleSingleStudentChange}
      isInvalid={validationErrors.id_no} // Add validation feedback
    />
    <Form.Control.Feedback type="invalid">{validationErrors.id_no}</Form.Control.Feedback>
  </Form.Group>

  {/* Full Name */}
  <Form.Group className="mb-2">
    <Form.Label>Full Name</Form.Label>
    <Form.Control
      name="full_name"
      type="text"
      value={singleStudent.full_name}
      onChange={handleSingleStudentChange}
      isInvalid={validationErrors.full_name} // Add validation feedback
    />
    <Form.Control.Feedback type="invalid">{validationErrors.full_name}</Form.Control.Feedback>
  </Form.Group>

  {/* Email */}
  <Form.Group className="mb-2">
    <Form.Label>Email</Form.Label>
    <Form.Control
      name="email"
      type="email"
      value={singleStudent.email}
      onChange={handleSingleStudentChange}
      isInvalid={validationErrors.email} // Add validation feedback
    />
    <Form.Control.Feedback type="invalid">{validationErrors.email}</Form.Control.Feedback>
  </Form.Group>

  <Row>
    {/* Gender Dropdown */}
    <Col>
      <Form.Group className="mb-2">
        <Form.Label>Gender</Form.Label>
        <Form.Select
          name="gender"
          value={singleStudent.gender}
          onChange={handleSingleStudentChange}
        >
          <option value="" hidden>Select Gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </Form.Select>
      </Form.Group>
    </Col>

    {/* Grade Level Dropdown */}
    <Col>
      <Form.Group className="mb-2">
        <Form.Label>Grade Level</Form.Label>
        <Form.Select
          name="grade_level"
          value={singleStudent.grade_level}
          onChange={handleSingleStudentChange}
        >
          <option value="" hidden>Select Grade Level</option>
          <option value="10">10</option>
          <option value="11">11</option>
        </Form.Select>
      </Form.Group>
    </Col>
  </Row>

  <Row>
    {/* Section Dropdown (Fetched from Database) */}
    <Col>
      <Form.Group className="mb-2">
        <Form.Label>Section</Form.Label>
        <Form.Select
          name="section"
          value={singleStudent.section}
          onChange={handleSingleStudentChange}
        >
          <option value="" hidden>Select Section</option>
          {sections.map((section) => (
          <option key={section.id} value={section.id}>
              {section.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </Col>

    {/* Strand Dropdown (Fixed Options) */}
    <Col>
    <Form.Group className="mb-2">
  <Form.Label>Strand</Form.Label>
  <Form.Select
    name="strand"
    value={singleStudent.strand}
    onChange={handleSingleStudentChange}
  >
    <option value="" hidden>Select Strand</option>
    <option value="ABM">ABM</option>
    <option value="ARTS & DESIGN">ARTS & DESIGN</option>
    <option value="STEM">STEM</option>
    <option value="HUMSS">HUMSS</option>
    <option value="TVL - Information and Communications Technology">TVL - Information and Communications Technology</option>
    <option value="TVL - Home Economics">TVL - Home Economics</option>
    <option value="TVL - Agri-Fishery Arts">TVL - Agri-Fishery Arts</option>
    <option value="TVL - Industrial Arts">TVL - Industrial Arts</option>
  </Form.Select>
</Form.Group>

    </Col>
  </Row>
</Form>

  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowSingleStudentModal(false)}>
      Cancel
    </Button>
    <Button variant="primary" onClick={handleAddSingleStudent}>
      Add Student
    </Button>
  </Modal.Footer>
</Modal>


  </Col>
</Row>
{/* Bulk Delete Confirmation Modal */}
<Modal show={showBulkDeleteModal} onHide={() => setShowBulkDeleteModal(false)} centered>
  <Modal.Header closeButton>
    <Modal.Title>Confirm Bulk Deletion</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <p>
      Are you sure you want to delete the following sections? All users in these
      sections will have their section information removed. This action cannot
      be undone.
    </p>
    <ul>
      {sections
        .filter((section) => selectedSections.includes(section.id))
        .map((section) => (
          <li key={section.id}>
            <strong>{section.name}</strong> (ID: {section.id})
          </li>
        ))}
    </ul>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowBulkDeleteModal(false)}>
      Cancel
    </Button>
    <Button variant="danger" onClick={handleConfirmedBulkDelete} disabled={deleting}>
      {deleting ? <Spinner animation="border" size="sm" /> : "Delete"}
    </Button>
  </Modal.Footer>
</Modal>

{/* Add Multiple Sections Modal */}
<Modal show={showAddModal} onHide={() => setShowAddModal(false)} centered>
  <Modal.Header closeButton>
    <Modal.Title>Add Multiple Sections</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form>
      <Form.Group>
        <Form.Label>Section Names</Form.Label>
        {sectionNames.map((section, index) => (
          <div key={index} className="d-flex align-items-center mb-2">
            <Form.Control
              type="text"
              value={section}
              onChange={(e) => handleSectionNameChange(e, index)}
              placeholder={`Section ${index + 1}`}
              className="me-2"
            />
            <Button variant="danger" size="sm" onClick={() => removeSection(index)}>
              <i className="bi bi-trash"></i>
            </Button>
          </div>
        ))}
      </Form.Group>

      {/* Add More Section Button */}
      <Button
        variant="outline-primary"
        className="w-100 mt-3"
        onClick={addNewSectionField}
      >
        + Add Another Section
      </Button>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowAddModal(false)}>
      Cancel
    </Button>
    <Button
      variant="success"
      onClick={handleAddSection}
      disabled={loading || sectionNames.length === 0}
    >
      {loading ? <Spinner animation="border" size="sm" /> : "Add Sections"}
    </Button>
  </Modal.Footer>
</Modal>

<Modal show={showCSVModal} onHide={() => setShowCSVModal(false)} size="lg" centered>
  <Modal.Header closeButton>
    <Modal.Title>Preview & Edit Students Before Upload</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {/* Table to display uploaded students */}
    {csvData.length > 0 ? (
      <>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>ID Number</th>
              <th>Full Name</th>
              <th>Email</th>
              <th>Gender</th>
              <th>Grade Level</th>
              <th>Section</th>
              <th>Strand</th>
            </tr>
          </thead>
          <tbody>
            {paginatedPreviewStudents.map((student, index) => (
              <tr key={index}>
                <td>{student.id_no}</td>
                <td>{student.full_name}</td>
                <td>{student.email}</td>
                <td>{student.gender}</td>
                <td>{student.grade_level}</td>
                <td>{student.section}</td>
                <td>{student.strand}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Pagination Controls */}
        <div className="d-flex justify-content-between align-items-center mt-3">
          <Button
            variant="secondary"
            disabled={previewCurrentPage === 1}
            onClick={() => handlePreviewPageChange(previewCurrentPage - 1)}
          >
            Previous
          </Button>
          <span>
            Page {previewCurrentPage} of {Math.ceil(csvData.length / previewStudentsPerPage)}
          </span>
          <Button
            variant="secondary"
            disabled={previewCurrentPage >= Math.ceil(csvData.length / previewStudentsPerPage)}
            onClick={() => handlePreviewPageChange(previewCurrentPage + 1)}
          >
            Next
          </Button>
        </div>
      </>
    ) : (
      <Alert variant="warning" className="text-center">No students loaded yet.</Alert>
    )}

    {/* Form to manually add a student */}
    <h5 className="mt-3">Manually Add a Student</h5>
    <Form>
      <Row className="mb-2">
        <Col>
          <Form.Control
            type="text"
            placeholder="ID Number"
            value={newStudent.id_no}
            onChange={(e) => setNewStudent({ ...newStudent, id_no: e.target.value })}
          />
        </Col>
        <Col>
          <Form.Control
            type="text"
            placeholder="Full Name"
            value={newStudent.full_name}
            onChange={(e) => setNewStudent({ ...newStudent, full_name: e.target.value })}
          />
        </Col>
        <Col>
          <Form.Control
            type="email"
            placeholder="Email"
            value={newStudent.email}
            onChange={(e) => setNewStudent({ ...newStudent, email: e.target.value })}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Form.Control
            type="text"
            placeholder="Gender"
            value={newStudent.gender}
            onChange={(e) => setNewStudent({ ...newStudent, gender: e.target.value })}
          />
        </Col>
        <Col>
          <Form.Control
            type="text"
            placeholder="Grade Level"
            value={newStudent.grade_level}
            onChange={(e) => setNewStudent({ ...newStudent, grade_level: e.target.value })}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <Form.Control
            type="text"
            placeholder="Section"
            value={newStudent.section}
            onChange={(e) => setNewStudent({ ...newStudent, section: e.target.value })}
          />
        </Col>
        <Col>
          <Form.Control
            type="text"
            placeholder="Strand"
            value={newStudent.strand}
            onChange={(e) => setNewStudent({ ...newStudent, strand: e.target.value })}
          />
        </Col>
      </Row>
      <Button variant="success" onClick={handleAddManualStudent} className="w-100">
        Add Student
      </Button>
    </Form>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowCSVModal(false)}>Close</Button>
    <Button variant="primary" onClick={handleUploadCSV} disabled={uploading}>
      {uploading ? <Spinner animation="border" size="sm" /> : "Upload Students"}
    </Button>
  </Modal.Footer>
</Modal>


      {/* Edit Section Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Section</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Section Name</Form.Label>
              <Form.Control
                type="text"
                value={selectedSection?.name || ""}
                onChange={(e) =>
                  setSelectedSection((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveEdit} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : "Save Changes"}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the section{" "}
          <strong>{sectionToDelete?.name}</strong>? This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteSection} disabled={deleting}>
            {deleting ? <Spinner animation="border" size="sm" /> : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
  show={showStudentsModal}
  onHide={handleCloseStudentsModal} // Use the function to close and reset pagination
  size="xl" // Make the modal wider
  centered
>
  <Modal.Header closeButton>
    <Modal.Title>Students in Section {selectedSectionName}</Modal.Title> {/* Dynamic Section Name */}
  </Modal.Header>
  <Modal.Body>
    {loading ? (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" />
      </div>
    ) : (
      <>
        {/* Search Input */}
        <Form.Control
          type="text"
          placeholder="Search by name or ID"
          value={searchQuery}
          onChange={handleSearchChange}
          className="mb-3"
        />

        {/* Students Table */}
        {filteredStudents.length > 0 ? (
          <>
            <Table striped bordered hover responsive>
            <thead>
  <tr>
    <th>#</th>
    <th onClick={() => handleSortSectionStudents("id_no")} style={{ cursor: "pointer" }}>
      Student ID <i className="bi bi-arrow-down-up"></i>
    </th>
    <th onClick={() => handleSortSectionStudents("full_name")} style={{ cursor: "pointer" }}>
      Full Name <i className="bi bi-arrow-down-up"></i>
    </th>
    <th onClick={() => handleSortSectionStudents("grade_level")} style={{ cursor: "pointer" }}>
      Grade Level <i className="bi bi-arrow-down-up"></i>
    </th>
    <th onClick={() => handleSortSectionStudents("has_taken_test")} style={{ cursor: "pointer" }}>
      Test Status <i className="bi bi-arrow-down-up"></i>
    </th>
    <th onClick={() => handleSortSectionStudents("chatbot_starts")} style={{ cursor: "pointer" }}>
      Chatbot Usage <i className="bi bi-arrow-down-up"></i>
    </th>
    <th className="text-center" onClick={() => handleSortSectionStudents("max_chatbot_starts")} style={{ cursor: "pointer" }}>
      Max Chatbot Usage <i className="bi bi-arrow-down-up"></i>
    </th>
    <th onClick={() => handleSortSectionStudents("is_active")} style={{ cursor: "pointer" }}>
      Account Status <i className="bi bi-arrow-down-up"></i>
    </th>
    <th>Actions</th>
  </tr>
</thead>

<tbody>
  {paginatedStudents.map((student, index) => (
    <tr key={student.id}>
      <td>{(currentPage - 1) * studentsPerPage + index + 1}</td>
      <td>{student.id_no}</td>
      <td>{student.full_name}</td>
      <td>{student.grade_level}</td>
      <td>
        {student.has_taken_test ? (
          <span className="text-success">Taken - {student.test_date}</span>
        ) : (
          <span className="text-danger">Not Taken</span>
        )}
      </td>
      <td>
        <span
          className={
            student.chatbot_starts >= student.max_chatbot_starts
              ? "text-danger fw-bold"
              : "text-success fw-bold"
          }
        >
          {student.chatbot_starts}/{student.max_chatbot_starts}{" "}
          {student.chatbot_starts >= student.max_chatbot_starts
            ? "(Maxed Out)"
            : `(Remaining: ${student.max_chatbot_starts - student.chatbot_starts})`}
        </span>
      </td>
      <td className="text-center">
  <div className="d-flex flex-column align-items-center">
    <Form.Control
      type="number"
      min="1"
      value={maxStarts[student.id] || student.max_chatbot_starts}
      onChange={(e) =>
        setMaxStarts((prev) => ({
          ...prev,
          [student.id]: e.target.value,
        }))
      }
      style={{ width: "80px", textAlign: "center" }}
    />
    <Button
      variant="primary"
      size="sm"
      className="mt-1"
      onClick={() => handleUpdateMaxStarts(student.id)}
      disabled={
        !maxStarts[student.id] ||
        parseInt(maxStarts[student.id]) === student.max_chatbot_starts
      }
    >
      Update
    </Button>
  </div>
</td>

      <td>
        {student.is_active ? (
          <span className="text-success fw-bold">Active</span>
        ) : (
          <span className="text-danger fw-bold">Inactive</span>
        )}
      </td>
      <td>
        <Button
          variant={student.is_active ? "danger" : "success"}
          size="sm"
          onClick={() => handleToggleActive(student.id, student.is_active)}
        >
          {student.is_active ? "Deactivate" : "Activate"}
        </Button>
      </td>
    </tr>
  ))}
</tbody>



            </Table>

            {/* Pagination Controls */}
            <div className="d-flex justify-content-between align-items-center mt-3">
              <Button
                variant="secondary"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage((prev) => prev - 1)}
              >
                Previous
              </Button>
              <span>
                Page {currentPage} of {Math.ceil(filteredStudents.length / studentsPerPage)}
              </span>
              <Button
                variant="secondary"
                disabled={currentPage >= Math.ceil(filteredStudents.length / studentsPerPage)}
                onClick={() => setCurrentPage((prev) => prev + 1)}
              >
                Next
              </Button>
            </div>
          </>
        ) : (
          <p className="text-center">No students found matching your search criteria.</p>
        )}
      </>
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={handleCloseStudentsModal}>
      Close
    </Button>
  </Modal.Footer>
</Modal>



    </Container>
  );
};

export default Management;

import React, { useEffect, useState } from 'react';
import '../../styles/style.css';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { toast } from 'react-toastify';
import * as bootstrap from 'bootstrap';
import api from '../../utils/api'; // Import the Axios instance
import Navbar from '../pages/Navbar'; // Import the Navbar component
import { TailSpin } from 'react-loader-spinner'; // Import TailSpin loader

function AccountDetails() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id_no: '',
    full_name: '',
    email: '',
    mobile_no: '',
    birthday: '',
    strand: '',
    sex: '',
    grade_level: '',
    section_id: '', // Include section_id for dropdown
  });
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [sections, setSections] = useState([]); // Section dropdown data
  const [loading, setLoading] = useState(true); // Add loading state
  const [isWalkIn, setIsWalkIn] = useState(false);

  // Check if passwords match
  useEffect(() => {
    setPasswordsMatch(newPassword === confirmNewPassword);
  }, [newPassword, confirmNewPassword]);

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) throw new Error('No auth token found');
  
        const response = await api.get('/auth/user/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const formattedBirthday = response.data.birthday
          ? new Date(response.data.birthday).toISOString().split('T')[0]
          : '';
  
        // Check if section is "WALK IN"
// Check if the student is a walk-in based on id_no
        const isWalkInStudent = response.data.id_no.includes("WALK");
        setIsWalkIn(isWalkInStudent); // Update state
          
        setFormData({
          id_no: response.data.id_no || '',
          full_name: response.data.full_name || '',
          email: response.data.email || '',
          mobile_no: response.data.mobile_no || '',
          birthday: formattedBirthday,
          strand: response.data.strand || '',
          sex: response.data.sex || '',
          grade_level: response.data.grade_level || '',
          section_id: response.data.section_id || '',
        });
  
        setIsWalkIn(isWalkInStudent); // Set the walk-in state
  
        const sectionResponse = await api.get(`/sections/?school_id=${response.data.school_id}`);
        setSections(sectionResponse.data || []);
      } catch (error) {
        console.error('Error fetching user data:', error.response ? error.response.data : error.message);
        toast.error('Failed to fetch user data');
      } finally {
        setLoading(false);
      }
    };
  
    fetchUserData();
  }, []);
  
// Return loading spinner
if (loading) {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100">
      <TailSpin color="#ffcc00" height={100} width={100} />
      <p className="mt-3" style={{ color: '#555', fontSize: '1.2rem' }}>
        Loading account details, please wait...
      </p>
    </div>
  );
}
if (!formData.full_name && !loading) {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100">
      <p className="mt-3" style={{ color: '#555', fontSize: '1.2rem' }}>
        Unable to load account details. Please try again later.
      </p>
    </div>
  );
}

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  // Handle profile picture upload
  

  // Handle password change
// Handle password change
const handlePasswordChange = async (e) => {
  e.preventDefault();

  if (!passwordsMatch) {
    toast.error('New passwords do not match!');
    return;
  }

  try {
    const response = await api.put(
      '/auth/change-password/',
      {
        current_password: currentPassword,
        new_password: newPassword,
        confirm_new_password: confirmNewPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      }
    );

    if (response.status === 200) {
      toast.success('Password updated successfully');

      // Clear input fields after successful password change
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
      setPasswordsMatch(true); // Reset validation state

      // Close modal properly
      const modalElement = document.getElementById('changePasswordModal');
      const modalInstance = bootstrap.Modal.getInstance(modalElement);
      if (modalInstance) modalInstance.hide();

      // Remove any remaining modal backdrop
      document.querySelectorAll('.modal-backdrop').forEach((backdrop) => backdrop.remove());
    }
  } catch (error) {
    // Handle backend error responses
    if (error.response && error.response.data && error.response.data.error) {
      toast.error(error.response.data.error); // Display the specific error message
    } else {
      console.error('Error updating password:', error.message);
      toast.error('An unexpected error occurred while updating the password');
    }
  }
};



  // Handle account details update
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.put('/auth/user/', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      if (response.status === 200) {
        toast.success('Account details updated successfully');
      } else {
        toast.error('Failed to update account details');
      }
    } catch (error) {
      console.error('Error updating account details:', error.response ? error.response.data : error.message);
      toast.error('Error updating account details');
    }
  };

  return (
    <div className="container-fluid p-0">
      {/* Navbar */}
      <Navbar />
  
      {/* Main Content */}
      <div className="container d-flex justify-content-center align-items-start"
     style={{ 
       minHeight: 'calc(100vh - 100px)',  // Moves content higher
       paddingTop: '30px',  // Moves card up
       paddingBottom: '20px'
     }}>


<div className="card"
     style={{
       width: '100%',
       maxWidth: '800px',
       padding: '20px',
       borderRadius: '15px',
       backgroundColor: '#f0f0f0'
     }}>

          <div className="card-body">
            <h5 className="card-subtitle mb-4 text-muted text-center">Account Details</h5>
            <div className="row g-4">
            <div className="col-md-12">
            <form onSubmit={handleSubmit}>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <label htmlFor="full_name" className="form-label">
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="full_name"
                        value={formData.full_name}
                        onChange={handleChange}
                        disabled={isWalkIn}
                      />
                    </div>
                    <div className="col-md-6">
  <label htmlFor="grade_level" className="form-label">
    Grade Level
  </label>
  <select
    className="form-select"
    id="grade_level"
    value={formData.grade_level}
    onChange={handleChange}
    required
  >
    <option value="" hidden>
      Select Grade Level
    </option>
    <option value="11">Grade 11</option>
    <option value="12">Grade 12</option>
  </select>
</div>

                  </div>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        disabled={isWalkIn}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="mobile_no" className="form-label">
                        Mobile No.
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="mobile_no"
                        value={formData.mobile_no}
                        onChange={handleChange}
                        
                      />
                    </div>
                  </div>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <label htmlFor="birthday" className="form-label">
                        Birthday
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="birthday"
                        value={formData.birthday}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
  <label htmlFor="strand" className="form-label">
    Strand
  </label>
  <select
    className="form-select"
    id="strand"
    value={formData.strand}
    onChange={handleChange}
    required
    disabled={isWalkIn}

  >
    <option value="" hidden>
      Select Strand
    </option>
    <option value="ABM">ABM</option>
    <option value="ARTS & DESIGN">ARTS & DESIGN</option>
    <option value="STEM">STEM</option>
    <option value="HUMSS">HUMSS</option>
    <option value="TVL - Information and Communications Technology">
      TVL - Information and Communications Technology
    </option>
    <option value="TVL - Home Economics">TVL - Home Economics</option>
    <option value="TVL - Agri-Fishery Arts">TVL - Agri-Fishery Arts</option>
    <option value="TVL - Industrial Arts">TVL - Industrial Arts</option>
  </select>
</div>

                  </div>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <label htmlFor="section_id" className="form-label">
                        Section
                      </label>
                      <select
  className="form-select"
  id="section_id"
  value={formData.section_id}
  onChange={handleChange}
  disabled={isWalkIn}
>
  <option value="" hidden>
    Select Section
  </option>
  {sections
    .filter((section) => section.name !== "WALK IN") // ❌ Exclude "WALK IN"
    .map((section) => (
      <option key={section.id} value={section.id}>
        {section.name}
      </option>
    ))}
</select>

                    </div>
                    <div className="col-md-6">
                      <label htmlFor="sex" className="form-label">
                        Sex
                      </label>
                      <select
                        className="form-select"
                        id="sex"
                        value={formData.sex}
                        onChange={handleChange}
                        required
                      >
                        <option value="" hidden>
                          Select Sex
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center gap-3 mt-4">
  <button type="submit" className="btn btn-warning px-4">
    Save Changes
  </button>
  <button 
  type="button" 
  className="btn btn-outline-danger px-4" 
  data-bs-toggle="modal" 
  data-bs-target="#changePasswordModal"
>
  Change Password
</button>

</div>
</form>

  {/* Change Password Modal */}
  <div
    className="modal fade"
    id="changePasswordModal"
    tabIndex="-1"
    aria-labelledby="changePasswordModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="changePasswordModalLabel">
            Change Password
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        
        <form onSubmit={handlePasswordChange}>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="currentPassword" className="form-label">
                Current Password
              </label>
              <input
                type="password"
                className="form-control"
                id="currentPassword"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="newPassword" className="form-label">
                New Password
              </label>
              <input
                type="password"
                className="form-control"
                id="newPassword"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="confirmNewPassword" className="form-label">
                Confirm New Password
              </label>
              <input
                type="password"
                className="form-control"
                id="confirmNewPassword"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                required
              />
              {!passwordsMatch && (
                <div className="text-danger mt-2">
                  Passwords do not match.
                </div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-warning"
              disabled={!passwordsMatch}
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</div>
</div>
</div>
</div>
</div>

  );
  
  
}

export default AccountDetails;

import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Form, Button, Nav, Navbar, Spinner, Alert } from 'react-bootstrap';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import FileSaver from 'file-saver';
import { CSVLink } from 'react-csv';
import html2canvas from 'html2canvas';
import { useNavigate } from 'react-router-dom';
import { FaHome, FaCommentDots, FaClipboardList } from 'react-icons/fa';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import api from '../../utils/api';  // Import the API utility
import { UserContext } from '../../context/UserContext';  // Add this import
import { useContext } from 'react';  // Add this line
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const validateUserRole = async (navigate, handleLogout) => {
  try {
    const token = localStorage.getItem('authToken');
    if (!token) throw new Error('No token found');

    const response = await api.get('/check_login_status/', {
      headers: { Authorization: `Bearer ${token}` },
    });

    const user = response.data.user_data;
    if (!user.is_staff && !user.is_superuser) {
      throw new Error('Unauthorized access');
    }
  } catch (error) {
    console.error('Role validation failed:', error);
    localStorage.clear();
    handleLogout();
    toast.error('Unauthorized access. You have been logged out.');
    navigate('/auth/guidance-login', { replace: true });
  }
};

// Register Chart.js components and plugins
ChartJS.register(...registerables, ChartDataLabels);
// Token refresh function
// Token refresh function
// Base chart options (common settings for all charts)

const refreshAccessToken = async (navigate, handleLogout) => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      throw new Error('No refresh token found');
    }

    const response = await api.post('/token/refresh/', { refresh: refreshToken });

    if (response.status === 200) {
      localStorage.setItem('authToken', response.data.access);
      return response.data.access;
    } else {
      throw new Error('Invalid or expired refresh token');
    }
  } catch (err) {
    console.error('Error refreshing access token:', err.message);
    toast.error('Session expired. Please log in again.');
    handleLogout();  // Use handleLogout to clear session and navigate
    return null;
  }
};



const Dashboard = () => {
  const { handleLogout } = useContext(UserContext);  // Destructure handleLogout from context
  const [dashboardData, setDashboardData] = useState([]);
  const [sections, setSections] = useState([]);
  const chartContainerRef = useRef(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    
    gradeLevel: 'Overall',
    strand: 'Overall',
    schoolYear: 'Overall',
    section: 'Overall', // Add section filter
  });
  
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  // Function to toggle sidebar state
  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const navigate = useNavigate();
  const chartRef = useRef(null);
  const fetchData = async () => {
    try {
      await validateUserRole(navigate, handleLogout); // Pass navigate and handleLogout
  
      let accessToken = localStorage.getItem('authToken');
      const queryParams = new URLSearchParams({
        gradeLevel: filters.gradeLevel !== 'Overall' ? filters.gradeLevel : '',
        strand: filters.strand !== 'Overall' ? filters.strand : '',
        school_year: filters.schoolYear !== 'Overall' ? filters.schoolYear : '',
        section: filters.section !== 'Overall' ? filters.section : '',
      });
  
      const response = await api.get(`/api/dashboard/?${queryParams.toString()}`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
  
      if (response.status === 200) {
        setDashboardData(response.data.conversations || []);
        setSections(response.data.sections || []);
      } else if (response.status === 401) {
        accessToken = await refreshAccessToken(navigate, handleLogout);
  
        if (accessToken) {
          const retryResponse = await api.get(`/api/dashboard/?${queryParams.toString()}`, {
            headers: { Authorization: `Bearer ${accessToken}` },
          });
  
          setDashboardData(retryResponse.data.conversations || []);
          setSections(retryResponse.data.sections || []);
        } else {
          console.error('Token refresh failed');
        }
      }
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError('Failed to load dashboard data.');
      navigate('/auth/guidance-login');
    } finally {
      setIsLoading(false);
    }
  };
  
  
  useEffect(() => {
    fetchData();
  }, [filters, navigate, handleLogout]); // Add all dependencies used within fetchData
  
  
  // Render loading state
  // Render loading state
if (isLoading) {
  return (
    <div className="text-center mt-5">
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
      <p>Loading dashboard...</p>
    </div>
  );
}


  // Filter handling
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    console.log(`Filter updated: ${name} = ${value}`); // Debugging
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  
  
  const getFilteredData = () => {
    let filteredData = dashboardData;
  
    // Apply grade level filter
    if (filters.gradeLevel !== 'Overall') {
      filteredData = filteredData.filter((d) => d.grade_level === filters.gradeLevel);
    }
  
    // Apply strand filter
    if (filters.strand !== 'Overall') {
      filteredData = filteredData.filter((d) =>
        d.strand.trim().toUpperCase() === filters.strand.trim().toUpperCase()
      );
    }
    
    // Apply school year filter
    if (filters.schoolYear !== 'Overall') {
      filteredData = filteredData.filter((d) => d.school_year?.trim() === filters.schoolYear.trim());
    }
  
    // Apply section filter
    if (filters.section !== 'Overall') {
      filteredData = filteredData.filter((d) => d.section?.trim() === filters.section.trim());
    }
  
    return filteredData;
  };
  
  const getAverageRiasecPerSection = () => {
    const filteredData = getFilteredData();
    const sectionScores = {};
  
    // Aggregate RIASEC scores for each section
    filteredData.forEach((conversation) => {
      const section = conversation.section?.trim() || 'No Section';
  
      if (!sectionScores[section]) {
        sectionScores[section] = {
          realistic: 0,
          investigative: 0,
          artistic: 0,
          social: 0,
          enterprising: 0,
          conventional: 0,
          count: 0, // Track number of students per section
        };
      }
  
      sectionScores[section].realistic += conversation.realistic_score || 0;
      sectionScores[section].investigative += conversation.investigative_score || 0;
      sectionScores[section].artistic += conversation.artistic_score || 0;
      sectionScores[section].social += conversation.social_score || 0;
      sectionScores[section].enterprising += conversation.enterprising_score || 0;
      sectionScores[section].conventional += conversation.conventional_score || 0;
      sectionScores[section].count += 1;
    });
  
    // Calculate rounded averages for each section
    const sectionLabels = Object.keys(sectionScores);
    const averages = {
      realistic: [],
      investigative: [],
      artistic: [],
      social: [],
      enterprising: [],
      conventional: [],
    };
  
    sectionLabels.forEach((section) => {
      const data = sectionScores[section];
      if (data.count > 0) {
        averages.realistic.push(Number((data.realistic / data.count).toFixed(2)));
        averages.investigative.push(Number((data.investigative / data.count).toFixed(2)));
        averages.artistic.push(Number((data.artistic / data.count).toFixed(2)));
        averages.social.push(Number((data.social / data.count).toFixed(2)));
        averages.enterprising.push(Number((data.enterprising / data.count).toFixed(2)));
        averages.conventional.push(Number((data.conventional / data.count).toFixed(2)));
      } else {
        averages.realistic.push(0);
        averages.investigative.push(0);
        averages.artistic.push(0);
        averages.social.push(0);
        averages.enterprising.push(0);
        averages.conventional.push(0);
      }
    });
  
    return {
      labels: sectionLabels,
      datasets: [
        {
          label: 'Realistic',
          data: averages.realistic,
          backgroundColor: '#28a745',
          borderColor: '#333',
          borderWidth: 2,
          borderRadius: 5,
          hoverBackgroundColor: '#555',
        },
        {
          label: 'Investigative',
          data: averages.investigative,
          backgroundColor: '#ffc107',
          borderColor: '#333',
          borderWidth: 2,
          borderRadius: 5,
          hoverBackgroundColor: '#555',
        },
        {
          label: 'Artistic',
          data: averages.artistic,
          backgroundColor: '#17a2b8',
          borderColor: '#333',
          borderWidth: 2,
          borderRadius: 5,
          hoverBackgroundColor: '#555',
        },
        {
          label: 'Social',
          data: averages.social,
          backgroundColor: '#6c757d',
          borderColor: '#333',
          borderWidth: 2,
          borderRadius: 5,
          hoverBackgroundColor: '#555',
        },
        {
          label: 'Enterprising',
          data: averages.enterprising,
          backgroundColor: '#fd7e14',
          borderColor: '#333',
          borderWidth: 2,
          borderRadius: 5,
          hoverBackgroundColor: '#555',
        },
        {
          label: 'Conventional',
          data: averages.conventional,
          backgroundColor: '#007bff',
          borderColor: '#333',
          borderWidth: 2,
          borderRadius: 5,
          hoverBackgroundColor: '#555',
        },
      ],
    };
  };
  
  
  // Base chart options (common settings for all charts)
  const baseChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20,
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      datalabels: {
        anchor: 'end',
        align: 'top',
        color: '#333',
        font: {
          weight: 'bold',
          size: 12,
        },
        formatter: (value) => value,
        offset: 4,
      },
      title: {
        display: false, // Chart-specific titles will override this
      },
    },
    scales: {
      x: {
        barThickness: 15,
        maxBarThickness: 20,
        title: {
          display: true,
          text: 'Categories',
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 5,
        },
        title: {
          display: true,
          text: 'Values',
        },
      },
    },
  };
  
  
// Specific chart options
const riasecChartOptions = {
  ...baseChartOptions,
  plugins: {
    ...baseChartOptions.plugins,
    title: {
      display: true,
      text: 'RIASEC Distribution',
    },
  },
};

const genderChartOptions = {
  ...baseChartOptions,
  plugins: {
    ...baseChartOptions.plugins,
    title: {
      display: true,
      text: 'Gender Distribution',
    },
  },
};

const averageRiasecChartOptions = {
  ...baseChartOptions,
  plugins: {
    ...baseChartOptions.plugins,
    title: {
      display: true,
      text: 'Average RIASEC Scores Per Section',
    },
  },
};

const gradeLevelChartOptions = {
  ...baseChartOptions,
  plugins: {
    ...baseChartOptions.plugins,
    title: {
      display: true,
      text: 'Grade Level Distribution',
    },
  },
};


const getRiasecChartData = () => {
  const filteredData = getFilteredData();

  const totalScores = {
    realistic: 0,
    investigative: 0,
    artistic: 0,
    social: 0,
    enterprising: 0,
    conventional: 0,
  };

  const strandScores = {
    ABM: { realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 },
    "ARTS & DESIGN": { realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 },
    STEM: { realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 },
    HUMSS: { realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 },
    "TVL - Information and Communications Technology": { realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 },
    "TVL - Home Economics": { realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 },
    "TVL - Agri-Fishery Arts": { realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 },
    "TVL - Industrial Arts": { realistic: 0, investigative: 0, artistic: 0, social: 0, enterprising: 0, conventional: 0 },
  };

  filteredData.forEach((conversation) => {
    totalScores.realistic += conversation.realistic_score;
    totalScores.investigative += conversation.investigative_score;
    totalScores.artistic += conversation.artistic_score;
    totalScores.social += conversation.social_score;
    totalScores.enterprising += conversation.enterprising_score;
    totalScores.conventional += conversation.conventional_score;

    // Directly match strand names
    const strandKey = Object.keys(strandScores).find(
      (key) => key.toLowerCase().trim() === conversation.strand.toLowerCase().trim()
    );
    
    if (strandKey) {
      strandScores[strandKey].realistic += conversation.realistic_score || 0;
      strandScores[strandKey].investigative += conversation.investigative_score || 0;
      strandScores[strandKey].artistic += conversation.artistic_score || 0;
      strandScores[strandKey].social += conversation.social_score || 0;
      strandScores[strandKey].enterprising += conversation.enterprising_score || 0;
      strandScores[strandKey].conventional += conversation.conventional_score || 0;
    }
    
  });

  // Dynamically filter out labels where total score is 0
  const labels = Object.entries(totalScores)
    .filter(([_, value]) => value > 0)
    .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1)); // Capitalize first letter

  return {
    labels,
    datasets: [
      {
        label: 'Total',
        data: labels.map((category) => totalScores[category.toLowerCase()]),
        backgroundColor: '#28a745',
        borderColor: '#333',
        borderWidth: 2,
        borderRadius: 5,
        hoverBackgroundColor: '#555',
      },
      {
        label: 'ABM',
        data: labels.map((category) => strandScores.ABM[category.toLowerCase()]),
        backgroundColor: '#fd7e14',
        borderColor: '#333',
        borderWidth: 2,
        borderRadius: 5,
        hoverBackgroundColor: '#555',
        hidden: labels.every((category) => strandScores.ABM[category.toLowerCase()] === 0),
      },
      {
        label: 'ARTS & DESIGN',
        data: labels.map((category) => strandScores["ARTS & DESIGN"][category.toLowerCase()]),
        backgroundColor: '#17a2b8',
        borderColor: '#333',
        borderWidth: 2,
        borderRadius: 5,
        hoverBackgroundColor: '#555',
        hidden: labels.every((category) => strandScores["ARTS & DESIGN"][category.toLowerCase()] === 0),
      },
      {
        label: 'STEM',
        data: labels.map((category) => strandScores.STEM[category.toLowerCase()]),
        backgroundColor: '#ffc107',
        borderColor: '#333',
        borderWidth: 2,
        borderRadius: 5,
        hoverBackgroundColor: '#555',
        hidden: labels.every((category) => strandScores.STEM[category.toLowerCase()] === 0),
      },
      {
        label: 'HUMSS',
        data: labels.map((category) => strandScores.HUMSS[category.toLowerCase()]),
        backgroundColor: '#dc3545',
        borderColor: '#333',
        borderWidth: 2,
        borderRadius: 5,
        hoverBackgroundColor: '#555',
        hidden: labels.every((category) => strandScores.HUMSS[category.toLowerCase()] === 0),
      },
      {
        label: 'TVL - Information and Communications Technology',
        data: labels.map((category) => strandScores["TVL - Information and Communications Technology"][category.toLowerCase()]),
        backgroundColor: '#007bff',
        borderColor: '#333',
        borderWidth: 2,
        borderRadius: 5,
        hoverBackgroundColor: '#555',
        hidden: labels.every((category) => strandScores["TVL - Information and Communications Technology"][category.toLowerCase()] === 0),
      },
      {
        label: 'TVL - Home Economics',
        data: labels.map((category) => strandScores["TVL - Home Economics"][category.toLowerCase()]),
        backgroundColor: '#6c757d',
        borderColor: '#333',
        borderWidth: 2,
        borderRadius: 5,
        hoverBackgroundColor: '#555',
        hidden: labels.every((category) => strandScores["TVL - Home Economics"][category.toLowerCase()] === 0),
      },
      {
        label: 'TVL - Agri-Fishery Arts',
        data: labels.map((category) => strandScores["TVL - Agri-Fishery Arts"][category.toLowerCase()]),
        backgroundColor: '#20c997',
        borderColor: '#333',
        borderWidth: 2,
        borderRadius: 5,
        hoverBackgroundColor: '#555',
        hidden: labels.every((category) => strandScores["TVL - Agri-Fishery Arts"][category.toLowerCase()] === 0),
      },
      {
        label: 'TVL - Industrial Arts',
        data: labels.map((category) => strandScores["TVL - Industrial Arts"][category.toLowerCase()]),
        backgroundColor: '#ffc0cb',
        borderColor: '#333',
        borderWidth: 2,
        borderRadius: 5,
        hoverBackgroundColor: '#555',
        hidden: labels.every((category) => strandScores["TVL - Industrial Arts"][category.toLowerCase()] === 0),
      },
    ],
  };
};

  const getSexChartData = () => {
    const filteredData = getFilteredData();
    let maleCount = 0;
    let femaleCount = 0;
  
    filteredData.forEach((conversation) => {
      if (conversation.sex === 'Male') {
        maleCount += 1;
      } else if (conversation.sex === 'Female') {
        femaleCount += 1;
      }
    });
  
    const totalCount = maleCount + femaleCount;
  
    return {
      labels: ['Students'], // Single x-axis label
      datasets: [
        {
          label: 'Male',
          data: [maleCount],
          backgroundColor: '#007bff',
          borderColor: '#333',
          borderWidth: 2,
          borderRadius: 5,
          hoverBackgroundColor: '#555',
          hidden: false, // Start visible
        },
        {
          label: 'Female',
          data: [femaleCount],
          backgroundColor: '#ff6ec7',
          borderColor: '#333',
          borderWidth: 2,
          borderRadius: 5,
          hoverBackgroundColor: '#555',
          hidden: false, // Start visible
        },
        {
          label: 'Total',
          data: [totalCount],
          backgroundColor: '#4caf50',
          borderColor: '#333',
          borderWidth: 2,
          borderRadius: 5,
          hoverBackgroundColor: '#555',
          hidden: false, // Start visible
        },
      ],
    };
  };
  const getGradeLevelChartData = () => {
    const filteredData = getFilteredData();
    let grade11Count = 0;
    let grade12Count = 0;
  
    filteredData.forEach((conversation) => {
      if (conversation.grade_level === '11') {
        grade11Count++;
      } else if (conversation.grade_level === '12') {
        grade12Count++;
      }
    });
  
    const totalCount = grade11Count + grade12Count;
  
    return {
      labels: ['Students'], // Single label for the x-axis
      datasets: [
        {
          label: 'Grade 11',
          data: [grade11Count],
          backgroundColor: '#ff6384',
          borderColor: '#333',
          borderWidth: 2,
          borderRadius: 5,
          hoverBackgroundColor: '#555',
          hidden: false, // Start visible
        },
        {
          label: 'Grade 12',
          data: [grade12Count],
          backgroundColor: '#36a2eb',
          borderColor: '#333',
          borderWidth: 2,
          borderRadius: 5,
          hoverBackgroundColor: '#555',
          hidden: false, // Start visible
        },
        {
          label: 'Total',
          data: [totalCount],
          backgroundColor: '#4caf50',
          borderColor: '#333',
          borderWidth: 2,
          borderRadius: 5,
          hoverBackgroundColor: '#555',
          hidden: false, // Start visible
        },
      ],
    };
  };
    
  const downloadPNG = async () => {
    try {
      const canvas = await html2canvas(chartContainerRef.current, { scrollY: -window.scrollY });
      canvas.toBlob((blob) => {
        FileSaver.saveAs(blob, 'dashboard_charts.png');
      });
    } catch (err) {
      console.error('Error capturing charts:', err);
    }
  };
  

  // CSV data for download
  const csvData = dashboardData.map((row) => ({
    Name: row.name,
    Sex: row.sex,
    Strand: row.strand,
    'Realistic Score': row.realistic_score,
    'Investigative Score': row.investigative_score,
    'Artistic Score': row.artistic_score,
    'Social Score': row.social_score,
    'Enterprising Score': row.enterprising_score,
    'Conventional Score': row.conventional_score,
  }));
  return (
    
    <Container fluid>
       {/* Display Error Alert if there's an error */}
    {error && (
      <Alert variant="danger" className="text-center my-4">
        {error}
      </Alert>
    )}

    <Row>
      {/* Main Content */}
      <Col xs={12} className="p-4">
        {/* Header Section */}
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3 className="fw-bold">Dashboard</h3>
          <DropdownButton
            id="dropdown-export-button"
            title="Export"
            variant="warning"
            className="me-2"
          >
            <Dropdown.Item onClick={downloadPNG}>Download All Charts (PNG)</Dropdown.Item>
            <Dropdown.Item as={CSVLink} data={csvData} filename="dashboard_data.csv">
              Download CSV
            </Dropdown.Item>
          </DropdownButton>
        </div>
  
        {/* Filters Section */}
        <Row className="mb-4">
          <Col md={3} style={{ maxWidth: '300px' }}>
            <Form.Group>
              <Form.Label>Grade Level</Form.Label>
              <Form.Select
                name="gradeLevel"
                value={filters.gradeLevel}
                onChange={handleFilterChange}
                className="w-100"
              >
                <option value="Overall">Overall</option>
                <option value="11">Grade 11</option>
                <option value="12">Grade 12</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3} style={{ maxWidth: '300px' }}>
            <Form.Group>
              <Form.Label>Strand</Form.Label>
              <Form.Select
                name="strand"
                value={filters.strand}
                onChange={handleFilterChange}
                className="w-100"
              >
                <option value="Overall">Overall</option>
                <option value="STEM">STEM</option>
                <option value="ABM">ABM</option>
                <option value="ARTS & DESIGN">ARTS & DESIGN</option>
                <option value="HUMSS">HUMSS</option>
                <option value="TVL - Information and Communications Technology">
                  TVL - Information and Communications Technology
                </option>
                <option value="TVL - Home Economics">TVL - Home Economics</option>
                <option value="TVL - Agri-Fishery Arts">TVL - Agri-Fishery Arts</option>
                <option value="TVL - Industrial Arts">TVL - Industrial Arts</option>
              </Form.Select>
            </Form.Group>
          </Col>
         
          <Col md={3} style={{ maxWidth: '300px' }}>
            <Form.Group>
              <Form.Label>Section</Form.Label>
              <Form.Select
                name="section"
                value={filters.section}
                onChange={handleFilterChange}
                className="w-100"
              >
                <option value="Overall">Overall</option>
                {sections.map((section) => (
                  <option key={section.id} value={section.name}>
                    {section.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3} style={{ maxWidth: '300px' }}>
            <Form.Group>
              <Form.Label>School Year</Form.Label>
              <Form.Select
                name="schoolYear"
                value={filters.schoolYear}
                onChange={handleFilterChange}
                className="w-100"
              >
                <option value="Overall">Overall</option>
                <option value="2022-2023">2022-2023</option>
                <option value="2023-2024">2023-2024</option>
                <option value="2024-2025">2024-2025</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
  
        {/* Charts Section */}
        <div ref={chartContainerRef}>
          <Row>
            {/* RIASEC Distribution Chart */}
<Col md={6} className="mb-4" style={{ maxWidth: '800px' }}>
  <div style={{ height: '500px' }}>
    <Bar data={getRiasecChartData()} options={riasecChartOptions} />
  </div>
</Col>

{/* Gender Distribution Chart */}
<Col md={6} className="mb-4" style={{ maxWidth: '650px' }}>
  <div style={{ height: '450px' }}>
    <Bar data={getSexChartData()} options={genderChartOptions} />
  </div>
</Col>

{/* Average RIASEC Scores Per Section Chart */}
<Col md={6} className="mb-4" style={{ maxWidth: '800px' }}>
  <div style={{ height: '450px' }}>
    <Bar data={getAverageRiasecPerSection()} options={averageRiasecChartOptions} />
  </div>
</Col>

{/* Grade Level Distribution Chart */}
<Col md={6} className="mb-4" style={{ maxWidth: '650px' }}>
  <div style={{ height: '450px' }}>
    <Bar data={getGradeLevelChartData()} options={gradeLevelChartOptions} />
  </div>
</Col>

          </Row>
        </div>
      </Col>
    </Row>
  </Container>
  
  


  );
};
export default Dashboard;

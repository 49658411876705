import React, { useState, useEffect } from 'react';
import { Table, Form, Button, InputGroup, Row, Col, Pagination, Container, Modal, Alert, Spinner } from 'react-bootstrap';
import { FaEye } from 'react-icons/fa';
import { Tabs, Tab } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import api from '../../utils/api';  // Import the API utility
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const Conversations = () => {
  // Lowercase, remove non-alphanumeric characters
  const [conversations, setConversations] = useState([]); // Initialize as an empty array
  const [filters, setFilters] = useState({
    searchQuery: '',
    strand: 'Overall',
    schoolYear: 'Overall',
    section: 'Overall', // Add section filter

  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [error, setError] = useState(null);

  // Modal-related state
  const [showModal, setShowModal] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [userDetails, setUserDetails] = useState(null); // Store user details
  const [loading, setLoading] = useState(false); // Add this state
  const [sectionMap, setSectionMap] = useState({});
  const [pageLoading, setPageLoading] = useState(true); // For page load spinner
  const [sections, setSections] = useState([]); // For storing sections

  useEffect(() => {
    const fetchSections = async () => {
      try {
        const response = await api.get('/api/sections'); // Adjust endpoint if needed
        if (response.status === 200) {
          setSections(response.data); // Save sections directly
        }
      } catch (error) {
        console.error('Error fetching sections:', error);
      }
    };
  
    fetchSections();
  }, []);
  
  const cleanAndParse = (data) => {
    if (!data) return []; // Return empty array for null/undefined
    if (Array.isArray(data)) return data; // Return if already an array
    try {
      // Attempt to parse as JSON
      const parsedData = JSON.parse(data);
      return Array.isArray(parsedData) ? parsedData : [parsedData]; // Ensure it returns an array
    } catch {
      // If parsing fails, assume it's a plain string and split by commas
      return data.split(',').map((item) => item.trim()); // Split and trim values
    }
  };
  const refreshAccessToken = async (navigate) => {
    try {
      const response = await api.post('/token/refresh/', {
        refresh: localStorage.getItem('refreshToken'),
      });
  
      if (response.status === 200) {
        localStorage.setItem('token', response.data.access);
        return response.data.access;
      } else {
        console.error('Refresh token invalid or expired:', response.data);
        navigate('/admin/login');
        return null;
      }
    } catch (err) {
      console.error('Error refreshing access token:', err);
      navigate('/admin/login');
      return null;
    }
  };
  
  
  const [courses, setCourses] = useState([]); // New state for courses
  const getFilteredConversations = () => {
    return conversations.filter((conversation) => {
        const normalizedStrand = (conversation.strand || "").trim().toLowerCase();
        const filterStrand = (filters.strand || "").trim().toLowerCase();

        // Ensure the strand filter works correctly
        if (filters.strand !== "Overall" && normalizedStrand !== filterStrand) {
            return false;
        }
        if (filters.section !== "Overall" && conversation.section !== filters.section) {
            return false;
        }

        // Ensure valid date parsing
        const conversationDate = new Date(conversation.created_at);
        if (isNaN(conversationDate.getTime())) {
            return false; // Skip invalid dates
        }

        // Handle school year filtering
        if (filters.schoolYear !== "Overall") {
            const schoolYearRange = filters.schoolYear.match(/\d{4}/g); // Extract year numbers
            if (!schoolYearRange || schoolYearRange.length !== 2) {
                return false; // Invalid school year format
            }

            const [startYear, endYear] = schoolYearRange.map(Number);

            // Academic year starts in June and ends in May
            const schoolYearStartDate = new Date(startYear, 5, 1); // June 1st of start year
            const schoolYearEndDate = new Date(endYear, 4, 31, 23, 59, 59); // May 31st of end year

            console.log(`Filtering for academic year: ${startYear}-${endYear}`);
            console.log(`Start Date: ${schoolYearStartDate.toISOString()}, End Date: ${schoolYearEndDate.toISOString()}`);
            console.log(`Conversation Date: ${conversationDate.toISOString()}`);

            // Ensure conversation date falls within academic year
            if (conversationDate >= schoolYearStartDate && conversationDate <= schoolYearEndDate) {
                return true;
            } else {
                return false;
            }
        }

        return true; // If no school year filter is applied, return all conversations
    });
};



  
  

  
  
  const filteredConversations = getFilteredConversations();
  
  const fetchConversationsAndCourses = async () => {
    setLoading(true);
    try {
      let accessToken = localStorage.getItem('token');
      const queryParams = new URLSearchParams({
        search: filters.searchQuery || '',
        strand: filters.strand !== 'Overall' ? filters.strand : '',
        school_year: filters.schoolYear !== 'Overall' ? filters.schoolYear : '',
        section: filters.section !== 'Overall' ? filters.section : '',
      });
  
      // Fetch conversations
      const response = await api.get(`/get-conversations/?${queryParams.toString()}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (response.status === 200) {
        const data = response.data.conversations;
        console.log('Fetched Data:', data);
  
        // ✅ Ensure each user only has their latest conversation
        const latestConversationsMap = new Map();
  
        data.forEach((conv) => {
          if (!latestConversationsMap.has(conv.user_id) || 
              new Date(conv.created_at) > new Date(latestConversationsMap.get(conv.user_id).created_at)) {
            latestConversationsMap.set(conv.user_id, conv);
          }
        });
  
        // Convert Map values back to an array
        const latestConversations = Array.from(latestConversationsMap.values());
  
        // Normalize and filter conversations
        const normalizedConversations = latestConversations.map((conversation) => ({
          ...conversation,
          riasec_course_recommendation: cleanAndParse(conversation.riasec_course_recommendation),
          strand_course_recommendation: cleanAndParse(conversation.strand_course_recommendation),
          isVisible: filters.strand === 'Overall' || conversation.strand === filters.strand,
        }));
  
        // Set state with only the latest conversations per user
        setConversations(normalizedConversations.filter((conv) => conv.isVisible));
      } else {
        console.error('Failed to fetch conversations:', response.status, response.statusText);
      }
  
      // Fetch courses with RIASEC areas
      const courseResponse = await api.get('https://node-backend-604521917673.asia-northeast1.run.app/api/courses');
      setCourses(courseResponse.data); // Save the fetched courses
    } catch (error) {
      console.error('Failed to fetch conversations or courses:', error);
      setError('Failed to load conversations.');
    } finally {
      setLoading(false); // Stop filter/search loading spinner
    }
  };
  
  // ✅ Call fetch function inside useEffect
  useEffect(() => {
    fetchConversationsAndCourses();
  }, [filters]);
  
  
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setPageLoading(true); // Indicate that the page is loading
        setError(null); // ✅ Clear error before fetching data
  
        let sectionsFetched = false;
        let conversationsFetched = false;
  
        // Fetch sections
        try {
          const sectionsResponse = await api.get('/api/sections');
          if (sectionsResponse.status === 200) {
            const sectionMap = sectionsResponse.data.reduce((acc, section) => {
              acc[section.id] = section.name;
              return acc;
            }, {});
            setSectionMap(sectionMap);
            sectionsFetched = true; // ✅ Mark sections as fetched
          }
        } catch (sectionError) {
          console.error('Error fetching sections:', sectionError);
        }
  
        // Fetch conversations and courses
        try {
          await fetchConversationsAndCourses();
          conversationsFetched = true; // ✅ Mark conversations as fetched
        } catch (convoError) {
          console.error('Error fetching conversations:', convoError);
        }
  
        // ✅ Only set error if both API calls fail
        if (!sectionsFetched && !conversationsFetched) {
          setError('Failed to load initial data.');
        }
      } catch (error) {
        console.error('Unexpected error:', error);
        setError('Failed to load initial data.');
      } finally {
        setPageLoading(false); // Stop page loading spinner
      }
    };
  
    fetchInitialData(); // ✅ Runs only on component mount
  }, []); // ✅ Empty dependency array ensures it runs once
  
  
  
  
  

  // Function to format date
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const formatRiasecCode = (riasecCode) => {
    if (!riasecCode || !Array.isArray(riasecCode)) return 'N/A';

    // Extract the first letter of each trait in the RIASEC code
    const code = riasecCode
      .map(trait => trait[0][0].toUpperCase())  // Get the first letter of the trait name
      .join('');  // Join the letters

    return <strong>{code}</strong>;  // Return the code in bold
  };

  // Function to handle filter changes
  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
    setLoading(true); // Start loading when filters change
  };
  

  const fetchUserDetails = async (userId) => {
    try {
      let accessToken = localStorage.getItem('token');
      const response = await api.get(`/get-user/${userId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      if (response.status === 200) {
        setUserDetails(response.data);
      } else {
        console.error('Failed to fetch user details:', response.status, response.statusText);
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };
  const handleSingleUserExport = async (data) => {
    toast.info("Exporting single user data...");
  
    try {
      // Ensure sections are fetched before export
      if (!Object.keys(sectionMap).length) {
        const response = await api.get('/api/sections'); // Fetch sections if not already fetched
        if (response.status === 200) {
          const map = response.data.reduce((acc, section) => {
            acc[section.id] = section.name;
            return acc;
          }, {});
          setSectionMap(map);
        }
      }
  
      // Prepare data with mapped sections
      const formattedData = data.map((row) => ({
        Name: row.Name || userDetails?.full_name || "N/A",
        "ID No": row["ID No"] || userDetails?.id_no || "N/A",
        Section: sectionMap[userDetails?.section_id] || "N/A", // Map section_id to section name
        "Grade Level": row["Grade Level"] || userDetails?.grade_level || "N/A",
        Age: row.Age || selectedConversation?.age || "N/A",
        Sex: row.Sex || userDetails?.sex || "N/A",
        Strand: row.Strand || userDetails?.strand || "N/A",
        "Test Taken Date": row["Test Taken Date"] || formatDate(selectedConversation?.created_at) || "N/A",
        "Realistic Score": row["Realistic Score"] || selectedConversation?.realistic_score || "N/A",
        "Investigative Score": row["Investigative Score"] || selectedConversation?.investigative_score || "N/A",
        "Artistic Score": row["Artistic Score"] || selectedConversation?.artistic_score || "N/A",
        "Social Score": row["Social Score"] || selectedConversation?.social_score || "N/A",
        "Enterprising Score": row["Enterprising Score"] || selectedConversation?.enterprising_score || "N/A",
        "Conventional Score": row["Conventional Score"] || selectedConversation?.conventional_score || "N/A",
        "RIASEC Course Recommendations": row["RIASEC Course Recommendations"] || 
          selectedConversation?.riasec_course_recommendation?.join(", ") || "N/A",
        "Strand Course Recommendations": row["Strand Course Recommendations"] || 
          selectedConversation?.strand_course_recommendation?.join(", ") || "N/A",
      }));
  
      // Convert to worksheet
      const ws = XLSX.utils.json_to_sheet(formattedData);
  
     // Adjust column widths
     ws["!cols"] = [
      { wch: 20 }, // Name
      { wch: 20 }, // ID No (increased width)
      { wch: 20 }, // Section
      { wch: 5 }, // Grade Level
      { wch: 5 }, // Age
      { wch: 10 }, // Sex
      { wch: 10 }, // Strand
      { wch: 15 }, // Test Taken Date (increased width)
      { wch: 5 }, // Realistic Score
      { wch: 5 }, // Investigative Score
      { wch: 5 }, // Artistic Score
      { wch: 5 }, // Social Score
      { wch: 5 }, // Enterprising Score
      { wch: 5 }, // Conventional Score
      { wch: 5 }, // RIASEC Course Recommendations
      { wch: 30 }, // Strand Course Recommendations
    ];

      // Create workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Single User Data");
  
      // Export as .xlsx file
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
      saveAs(blob, `${userDetails?.full_name || "user"}_details.xlsx`);
  
      toast.success("Single user export successful!");
    } catch (error) {
      toast.error("Export failed. Please try again.");
      console.error("Export Error:", error);
    }
  };
  
  
  
  const handleExportClick = () => {
    toast.info("Exporting...");
  
    try {
      // Define headers
      const headers = [
        "Name",
        "ID No",
        "Section",
        "Grade Level",
        "Age",
        "Sex",
        "Strand",
        "Test Taken Date",
        "Realistic Score",
        "Investigative Score",
        "Artistic Score",
        "Social Score",
        "Enterprising Score",
        "Conventional Score",
        "RIASEC Course Recommendations",
        "Strand Course Recommendations",
      ];
  
      // Prepare data
      const formattedData = csvAllData.map((row) => {
        const riasecRecommendations = cleanAndParse(row["RIASEC Course Recommendations"]);
        const strandRecommendations = cleanAndParse(row["Strand Course Recommendations"]);
  
        return {
          Name: row.Name || "N/A",
          "ID No": row["ID No"] || "N/A",
          Section: row.Section || "N/A",
          "Grade Level": row["Grade Level"] || "N/A",
          Age: row.Age || "N/A",
          Sex: row.Sex || "N/A",
          Strand: row.Strand || "N/A",
          "Test Taken Date": row["Test Taken Date"] || "N/A",
          "Realistic Score": row["Realistic Score"] || "N/A",
          "Investigative Score": row["Investigative Score"] || "N/A",
          "Artistic Score": row["Artistic Score"] || "N/A",
          "Social Score": row["Social Score"] || "N/A",
          "Enterprising Score": row["Enterprising Score"] || "N/A",
          "Conventional Score": row["Conventional Score"] || "N/A",
          "RIASEC Course Recommendations": riasecRecommendations.join(", "),
          "Strand Course Recommendations": strandRecommendations.join(", "),
        };
      });
  
      // Convert to worksheet
      const ws = XLSX.utils.json_to_sheet(formattedData);
  
          // Adjust column widths
          ws["!cols"] = [
            { wch: 20 }, // Name
            { wch: 25 }, // ID No (increased width)
            { wch: 20 }, // Section
            { wch: 5 }, // Grade Level
            { wch: 5 }, // Age
            { wch: 10 }, // Sex
            { wch: 10 }, // Strand
            { wch: 15 }, // Test Taken Date (increased width)
            { wch: 5 }, // Realistic Score
            { wch: 5 }, // Investigative Score
            { wch: 5 }, // Artistic Score
            { wch: 5 }, // Social Score
            { wch: 5 }, // Enterprising Score
            { wch: 5 }, // Conventional Score
            { wch: 30 }, // RIASEC Course Recommendations
            { wch: 30 }, // Strand Course Recommendations
          ];
      
  
      // Create workbook
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Conversations");
  
      // Export as .xlsx file
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
      saveAs(blob, "conversations_data.xlsx");
  
      toast.success("Export successful!");
    } catch (error) {
      toast.error("Export failed. Please try again.");
      console.error("Export Error:", error);
    }
  };
  
  
  

  // Handle modal open and fetching details
  const handleShowDetails = (conversation) => {
    if (!conversation.user_id) {
      console.error("No user_id found in conversation");
      return;
    }
    
    setSelectedConversation(conversation); // Set the selected conversation details
    fetchUserDetails(conversation.user_id); // Fetch user details using user_id
    setShowModal(true); // Show the modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
    setSelectedConversation(null);
    setUserDetails(null); // Reset user details
  };

  // Handle pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentConversations = conversations.slice(indexOfFirstItem, indexOfLastItem); // Display conversations for the current page

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const abbreviateStrand = (strand) => {
    const formattedStrand = (strand || "").trim().toLowerCase();
    switch (formattedStrand) {
      case 'tvl - information and communications technology':
        return 'TVL - ICT';
      case 'tvl - home economics':
        return 'TVL - HE';
      case 'tvl - agri-fishery arts':
        return 'TVL - AFA';
      case 'tvl - industrial arts':
        return 'TVL - IA';
      default:
        return strand; // Return the original strand if no abbreviation is needed
    }
  };
  
  
  const csvData = [
    {
      Name: userDetails?.full_name || 'N/A',
      "ID No": userDetails?.id_no || 'N/A',
      Section: userDetails?.section || 'N/A', // Ensure userDetails includes section
      "Grade Level": userDetails?.grade_level || 'N/A',
      Age: selectedConversation?.age || 'N/A',
      Sex: userDetails?.sex || 'N/A',
      Strand: userDetails?.strand || 'N/A',
      "Test Taken Date": formatDate(selectedConversation?.created_at) || 'N/A',
      "Realistic Score": selectedConversation?.realistic_score || 'N/A',
      "Investigative Score": selectedConversation?.investigative_score || 'N/A',
      "Artistic Score": selectedConversation?.artistic_score || 'N/A',
      "Social Score": selectedConversation?.social_score || 'N/A',
      "Enterprising Score": selectedConversation?.enterprising_score || 'N/A',
      "Conventional Score": selectedConversation?.conventional_score || 'N/A',
      "RIASEC Course Recommendations":
        selectedConversation?.riasec_course_recommendation?.join(", ") || 'N/A',
      "Strand Course Recommendations":
        selectedConversation?.strand_course_recommendation?.join(", ") || 'N/A',
    },
  ];
  
  
  
  
  const csvAllData = conversations.map((conversation) => ({
    Name: conversation.name || 'N/A',
    "ID No": conversation.id_no || 'N/A',
    Section: conversation.section || 'N/A',
    "Grade Level": conversation.grade_level || 'N/A',
    Age: conversation.age || 'N/A',
    Sex: conversation.sex || 'N/A',
    Strand: conversation.strand || 'N/A',
    "Test Taken Date": formatDate(conversation.created_at) || 'N/A', // Add this line
    "Realistic Score": conversation.realistic_score || 'N/A',
    "Investigative Score": conversation.investigative_score || 'N/A',
    "Artistic Score": conversation.artistic_score || 'N/A',
    "Social Score": conversation.social_score || 'N/A',
    "Enterprising Score": conversation.enterprising_score || 'N/A',
    "Conventional Score": conversation.conventional_score || 'N/A',
    "RIASEC Course Recommendations": conversation.riasec_course_recommendation?.join(', ') || 'N/A',
    "Strand Course Recommendations": conversation.strand_course_recommendation?.join(', ') || 'N/A',
  }));
  


  if (pageLoading) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
        <p>Loading conversations...</p>
      </div>
    );
  }
  
  return (
    <Container fluid>
      <ToastContainer />
      <Row>
        {/* Main Content */}
        <Col xs={12} className="p-4">
          <h2 className="fw-bold text-center mb-4">Conversations</h2>
  
          {error && <div className="alert alert-danger">{error}</div>}
  
          {/* Search and Filters */}
          {/* Labels Row for Search, Strand, Sections, School Year */}
{/* Labels Row (Strand, Sections, School Year) */}
{/* First Row: Only the three labels aligned (Strand, Sections, School Year) */}
{/* Single Row: Search, Strand, Sections, School Year, Export */}
<Row className="mb-4 d-flex align-items-end justify-content-between">

  {/* Column 1: Search */}
  <Col xs={12} md={3}>
    <Form.Group controlId="searchGroup">
      {/* An invisible label that still takes up vertical space */}
      <Form.Label className="invisible">Search</Form.Label>
      <InputGroup>
        <Form.Control
          type="text"
          placeholder="Search"
          name="searchQuery"
          value={filters.searchQuery}
          onChange={handleFilterChange}
          style={{ fontSize: "0.95rem", padding: "0.5rem" }}
        />
        <Button
          variant="warning"
          style={{ padding: "0.5rem" }}
        >
          <i className="fa fa-search"></i>
        </Button>
      </InputGroup>
    </Form.Group>
  </Col>

  {/* Column 2: Strand */}
  <Col xs={6} md={2}>
    <Form.Group>
      <Form.Label>Strand</Form.Label>
      <Form.Select
  name="strand"
  value={filters.strand}
  onChange={handleFilterChange}
  style={{ fontSize: "0.8rem", padding: "0.3rem" }}
>
  <option value="Overall">Overall</option>
  <option value="STEM">STEM</option>
  <option value="ABM">ABM</option>
  <option value="ARTS & DESIGN">ARTS & DESIGN</option>
  <option value="HUMSS">HUMSS</option>
  <option value="TVL - INFORMATION AND COMMUNICATIONS TECHNOLOGY">
  TVL - INFORMATION AND COMMUNICATIONS TECHNOLOGY
  </option>
  <option value="TVL - HOME ECONOMICS">TVL - HOME ECONOMICS</option>
  <option value="TVL - AGRI-FISHERY ARTS">TVL - AGRI-FISHERY ARTS</option>
  <option value="TVL - INDUSTRIAL ARTS">TVL - INDUSTRIAL ARTS</option>
</Form.Select>

    </Form.Group>
  </Col>

  {/* Column 3: Sections */}
  <Col xs={6} md={2}>
    <Form.Group>
      <Form.Label>Sections</Form.Label>
      <Form.Select
        name="section"
        value={filters.section}
        onChange={(e) => setFilters({ ...filters, section: e.target.value })}
        style={{ fontSize: "0.8rem", padding: "0.3rem" }}
      >
        <option value="Overall">Overall</option>
        {sections.map((section) => (
          <option key={section.id} value={section.name}>
            {section.name}
          </option>
        ))}
      </Form.Select>
    </Form.Group>
  </Col>

  {/* Column 4: School Year */}
  <Col xs={6} md={2}>
    <Form.Group>
      <Form.Label>School Year</Form.Label>
      <Form.Select
        name="schoolYear"
        value={filters.schoolYear}
        onChange={handleFilterChange}
        style={{ fontSize: "0.8rem", padding: "0.3rem" }}
      >
        <option value="Overall">Overall</option>
        <option value="2022-2023">2022-2023</option>
        <option value="2023-2024">2023-2024</option>
        <option value="2024-2025">2024-2025</option>
      </Form.Select>
    </Form.Group>
  </Col>

  {/* Column 5: Export Button */}
  <Col xs={12} md={2} className="text-end">
    <Form.Group>
      {/* Invisible label for vertical alignment */}
      <Form.Label className="invisible">Export</Form.Label>
      <Button
        variant="primary"
        onClick={handleExportClick}
        className="w-100"
        style={{
          fontSize: "0.9rem",
          padding: "0.4rem 0.8rem",
        }}
      >
        <i className="fa fa-download"></i> Export All
      </Button>
    </Form.Group>
  </Col>

</Row>


          {/* Conversations Table */}
          <Table striped bordered hover responsive>
  <thead style={{ backgroundColor: 'var(--primary)', color: '#fff' }}>
    <tr>
      <th className="text-center">Date Taken</th>
      <th className="text-center">ID No</th>
      <th className="text-center">Student Name</th>
      <th className="text-center">Section</th>
      <th className="text-center">Age</th>
      <th className="text-center">Sex</th>
      <th className="text-center">Strand</th>
      <th className="text-center">RIASEC Code</th>
      <th className="text-center">Actions</th>
    </tr>
  </thead>
  <tbody>
  {loading ? (
    <tr>
      <td colSpan="9" className="text-center">
        <Spinner animation="border" size="sm" /> Loading conversations...
      </td>
    </tr>
  ) : filteredConversations.length > 0 ? (
    filteredConversations.slice(indexOfFirstItem, indexOfLastItem).map((conversation, index) => (
      <tr key={conversation.id || index}>
        <td className="text-center">{formatDate(conversation.created_at)}</td>
        <td className="text-center">{conversation.id_no || "N/A"}</td>
        <td className="text-center">{conversation.name || "N/A"}</td>
        <td className="text-center">{conversation.section || "N/A"}</td>
        <td className="text-center">{conversation.age !== null ? conversation.age : "N/A"}</td>
        <td className="text-center">{conversation.sex || "N/A"}</td>
        <td className="text-center">{abbreviateStrand(conversation.strand) || "N/A"}</td>
        <td className="text-center">{formatRiasecCode(conversation.riasec_code)}</td>
        <td className="text-center">
          <Button
            variant="link"
            className="text-warning"
            onClick={() => handleShowDetails(conversation)}
            title="View Details"
          >
            <FaEye />
          </Button>
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="9" className="text-center">
        No conversations available. Adjust filters or search to find results.
      </td>
    </tr>
  )}
</tbody> 

</Table>

  
          {/* Pagination */}
          {conversations.length > 0 && (
            <Row>
              <Col md={6}>
                <div>
                  Items per page:
                  <Form.Select
                    style={{ width: '80px', display: 'inline-block' }}
                    value={itemsPerPage}
                    onChange={(e) => setItemsPerPage(Number(e.target.value))}
                  >
                    <option value={5}>5</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                  </Form.Select>
                </div>
              </Col>
              <Col md={6}>
                <Pagination className="float-end">
                  <Pagination.First />
                  <Pagination.Prev />
                  <Pagination.Item onClick={() => paginate(1)}>1</Pagination.Item>
                  <Pagination.Item onClick={() => paginate(2)}>2</Pagination.Item>
                  <Pagination.Item onClick={() => paginate(3)}>3</Pagination.Item>
                  <Pagination.Next />
                  <Pagination.Last />
                </Pagination>
                
            </Col>
            
          </Row>
              )}
        </Col>
        </Row>


  <Modal show={showModal} onHide={handleCloseModal} size="lg">
  <Modal.Header
    closeButton
    style={{
      paddingBottom: '0.5rem',
      paddingTop: '0.5rem',
      position: 'relative',
      backgroundColor: '#f8f9fa',
    }}
  >
    <Modal.Title className="w-100 text-center" style={{ paddingTop: '0.5rem', fontWeight: 'bold' }}>
      {userDetails?.full_name ? `${userDetails.full_name}'s Details` : 'Details'}
    </Modal.Title>
  </Modal.Header>

  <Modal.Body style={{ backgroundColor: '#f4f6f9' }}>
    {selectedConversation && userDetails ? (
      <Tabs
        defaultActiveKey="userInfo"
        id="details-tabs"
        className="mb-3 fs-5 p-2"
        variant="pills"
        fill
        style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}
      >
        {/* User Information Tab */}
        <Tab eventKey="userInfo" title="User Information">
        <div className="p-3">
  {[
    { label: 'Name', value: userDetails.full_name },
    { label: 'ID No', value: userDetails.id_no },
    { label: 'Email', value: userDetails.email },
    { label: 'Mobile No', value: userDetails.mobile_no },
    { label: 'Grade Level', value: userDetails.grade_level },
    { label: 'Sex', value: userDetails.sex },
    { label: 'Strand', value: userDetails.strand },
    { label: 'Age', value: selectedConversation.age || 'N/A' },
    { label: 'Test Taken Date', value: formatDate(selectedConversation.created_at) }, // Add this line
  ].map((item, idx) => (
    <Row className="mb-2 justify-content-center" key={idx}>
      <Col md={4} className="text-right">
        <strong>{item.label}:</strong>
      </Col>
      <Col md={6} className="text-left">
        {item.value}
      </Col>
    </Row>
  ))}
</div>
        </Tab>

        {/* RIASEC Scores Tab */}
        <Tab eventKey="riasecScores" title="RIASEC Scores">
          <div className="p-3 text-center">
            {[
              { label: 'Realistic', score: selectedConversation.realistic_score },
              { label: 'Investigative', score: selectedConversation.investigative_score },
              { label: 'Artistic', score: selectedConversation.artistic_score },
              { label: 'Social', score: selectedConversation.social_score },
              { label: 'Enterprising', score: selectedConversation.enterprising_score },
              { label: 'Conventional', score: selectedConversation.conventional_score },
            ].map((item, idx) => (
              <Row className="mb-2 justify-content-center" key={idx}>
                <Col md={6}>
                  <strong>{item.label} Score:</strong>
                </Col>
                <Col md={6}>{item.score}</Col>
              </Row>
            ))}
            <Row className="mb-2 justify-content-center">
              <Col md={6}>
                <strong>RIASEC Code:</strong>
              </Col>
              <Col md={6}>{formatRiasecCode(selectedConversation.riasec_code)}</Col>
            </Row>
          </div>
        </Tab>

{/* RIASEC Course Recommendations Tab */}
<Tab eventKey="courseRecommendations" title="Course Recommendations">
  <div className="p-3">
    <p className="text-center mb-4">
      <strong>RIASEC Course Recommendations:</strong>
    </p>
    <Row className="justify-content-center">
      {selectedConversation.riasec_course_recommendation && selectedConversation.riasec_course_recommendation.length > 0 ? (
        selectedConversation.riasec_course_recommendation.map((course, index) => {
          const riasecMapping = courses.find(c => c.name === course)?.riasec_area || [];

          // Abbreviate RIASEC areas (e.g., Realistic -> R, Investigative -> I, etc.)
          const abbreviatedRIASEC = riasecMapping.map(area => area[0].toUpperCase()).join('');

          return (
            <Col xs={12} md={6} key={index} className="mb-3">
              <li style={{ fontSize: '1.1rem', lineHeight: '1.5' }}>
                <span>{course}</span>{' '}
                {abbreviatedRIASEC && (
                  <span style={{ fontWeight: 'bold', color: '#007bff' }}> - {abbreviatedRIASEC}</span>
                )}
              </li>
            </Col>
          );
        })
      ) : (
        <p className="text-center">No recommendations available</p>
      )}
    </Row>
  </div>
</Tab>



        {/* Strand Recommendations Tab */}
        <Tab eventKey="strandRecommendations" title="Strand Recommendations">
          <div className="p-3">
            <p className="text-center">
              <strong>Strand Course Recommendations:</strong>
            </p>
            <Row className="justify-content-center">
              {selectedConversation.strand_course_recommendation && selectedConversation.strand_course_recommendation.length > 0 ? (
                selectedConversation.strand_course_recommendation.map((course, index) => (
                  <Col md={6} key={index}>
                    <li>{course}</li>
                  </Col>
                ))
              ) : (
                <p className="text-center">No recommendations available</p>
              )}
            </Row>
          </div>
        </Tab>
      </Tabs>
    ) : (
      <p className="text-center">Loading details...</p>
    )}
  </Modal.Body>

  <Modal.Footer className="d-flex justify-content-end" style={{ backgroundColor: '#f8f9fa' }}>
  <Button
    className="btn btn-success"
    style={{ backgroundColor: '#198754', color: '#fff' }}
    onClick={() => handleSingleUserExport(csvData)}
  >
    Download XLSX
  </Button>
</Modal.Footer>
</Modal>


    </Container>
  );
};

export default Conversations;

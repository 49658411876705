import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { useEffect } from 'react';

const Modal = ({ target, title, size, show, onHide, children }) => {
    useEffect(() => {
        const modalElement = document.getElementById(target);

        if (!modalElement) {
            console.error(`Modal target element with ID "${target}" not found.`);
            return;
        }

        // Initialize Bootstrap Modal (only if it exists)
        let bootstrapModal;
        if (window.bootstrap && window.bootstrap.Modal) {
            bootstrapModal = new window.bootstrap.Modal(modalElement);
        } else {
            console.error("Bootstrap Modal is not available. Make sure Bootstrap JS is loaded correctly.");
            return;
        }

        // Show or hide modal based on `show` prop
        if (show) {
            bootstrapModal.show();
        } else {
            bootstrapModal.hide();
        }

        return () => {
            if (bootstrapModal) {
                bootstrapModal.dispose();
            }
        };
    }, [show, target]);

    return (
        <div className="modal fade" id={target} tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className={`modal-dialog modal-dialog-scrollable ${size || ''}`}>
                <div className="modal-content">
                    <div className="modal-header bg-primary">
                        <h5 className="modal-title">{title}</h5>
                        <button
                            type="button"
                            className="btn-close text-white"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={onHide} // Call the onHide function directly
                        ></button>
                    </div>
                    <div className="modal-body">{children}</div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
